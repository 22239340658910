import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  DetailedPackage,
  DocReadness,
  DocumentInterace
} from '../models/newDocument.model';

@Injectable({
  providedIn: 'root'
})
export class DocumentsService {
  private activeDocId: number | null = null;

  public docReadness$: BehaviorSubject<DocReadness> = new BehaviorSubject(
    {} as DocReadness
  );

  public profileObs$: BehaviorSubject<DetailedPackage> = new BehaviorSubject(
    {} as DetailedPackage
  );

  public packageUser$: BehaviorSubject<DocumentInterace> = new BehaviorSubject(
    {} as DocumentInterace
  );

  constructor() {
    this.setDoc$(this.getDoc());
  }

  public setActiveDocId(id: number | null) {
    this.activeDocId = id;
  }

  public getActiveDocId() {
    return this.activeDocId;
  }

  getProfileObs(): Observable<DetailedPackage> {
    return this.profileObs$.asObservable();
  }

  setProfileObs(profile: DetailedPackage) {
    this.profileObs$.next(profile);
  }

  public setDocReadness(doc: DocReadness): void {
    this.docReadness$.next(doc);
  }

  public setDoc(doc: DocumentInterace): void {
    this.setDoc$(doc);
    localStorage.setItem('selectedDoc', JSON.stringify(doc));
  }

  private setDoc$(doc: DocumentInterace): void {
    this.packageUser$.next(doc);
  }

  public getDoc$(): Observable<DocumentInterace> {
    return this.packageUser$;
  }

  // eslint-disable-next-line class-methods-use-this
  public getDoc(): DocumentInterace {
    return JSON.parse(localStorage.getItem('selectedDoc') || '{}');
  }
}
