import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';

export interface LoginResponse {
  success: boolean;
  data: {
    access_token: string;
    expires_in: number;
    refresh_token: string;
  };
}

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  public baseRestURL = '';

  constructor(
    private readonly http: HttpClient,
    private readonly configService: ConfigService
  ) {
    this.baseRestURL = this.configService.baseRestURL;
  }

  public login(username: string, password: string) {
    const params = new HttpParams()
      .set('userName', username)
      .set('password', password);

    return this.http.post<LoginResponse>(
      `${this.baseRestURL}usermanagement/registration/login`,
      params
    );
  }

  public resetPassword(email: string) {
    const params = new HttpParams().set('email', email);

    return this.http.post<LoginResponse>(
      `${this.baseRestURL}usermanagement/registration/reset-password`,
      {},
      { params, observe: 'response' }
    );
  }
}
