export interface CompanyCardResultDto {
  success: boolean;
  data: CompanyCardDto;
}
export interface DocumentsAPIResponseModel {
  success: boolean,
  data: DocumentInterace[]
}
export interface SingleDocumentAPIResponseModel {
  success: boolean,
  data: DocumentInterace
}

export interface DocumentInterace {
  id: number,
  type: 'ACCREDITATE_PACKAGE' | 'ACCREDITATE_DOCUMENT' | 'USER_MESSAGE' | 'BACK_CALL',
  status: DocStatuses,
  user: {
    id: number,
    name: string,
    inn: string,
    address: string
  },
  createdDate: Date,
  updatedDate: Date,
  moderatorId: number,
  moderatorName?: string,
  attributes: [],
  packageInfo: {
    id: number,
    name: string
  }
  documentInfo: {
    id: number,
    name: string,
    packageId: number,
    packageName: string,
    comment?: string
  }
}

export interface DetailedPackage {
  success: boolean,
  data: {
    id?: number,
    isActualDocsList: boolean,
    userId: number,
    docsPackageId: number,
    status: DocStatuses,
    docsPackageSchemeId: number,
    counterparty: {
      id: 4,
      name: string
    },
    taxSystem: {
      id: 4,
      name: string
    },
    userTypeId: number,
    description: string,
    content: [
      {
        isExpired: boolean,
        isExpiredSoon: boolean,
        experationType: string,
        actualDateTo: number,
        documentId: number,
        premoderationStatus: PremoderationStatus,
        isRefusedByUser: boolean,
        userId: number,
        docsPackageId: number,
        status: string,
        moderatorName: string,
        isComplex: boolean,
        childs: DocChilds[],
        name: string,
        filename: string,
        displayedFilename: string,
        role: {
          id: number,
          name: string,
          description: string,
          required: true,
          order: number
        },
        comment: string,
        userComment: string,
        moderatorComment: string,
        premoderationComment: string,
        createdDate: number
      }
    ],
    version: number
  }

}

export interface DocChilds {
  filename: string
  id: number
  parentDocumentId: number
}

export interface DocReadness {
  TOTAL: number,
  REJECTED: number,
  POSTPONED: number,
  IN_PROGRESS: number,
  APPROVED: number,
  NEW: number,
  COMPLETE_STATUS: CompleteStatuses
}

export enum CompleteStatuses {
  EMPTY = '',
  REJECTED = 'REJECTED',
  APPROVED = 'APPROVED',
  POSTPONED = 'POSTPONED'
}

export enum DocTypes {
  empty = 'empty',
  file = 'file',
  html = 'html',
  pdf = 'pdf',
  image = 'image'
}

export enum PremoderationStatus {
  PRE_APPROVED = 'PRE_APPROVED',
  PRE_REJECTED = 'PRE_REJECTED'
}
export enum DocStatuses {
  CREATED = 'CREATED',
  REJECTED = 'REJECTED',
  APPROVED = 'APPROVED',
  IN_PROGRESS = 'IN_PROGRESS'
}

export interface CompanyCardDto {
  userType: any;
  inn: number;
  ogrn: number;
  okpo: number;
  okato: number;
  okved: number;
  okvedName: string;
  kpp: number;
  kpp2: number;
  postAddress: string;
  realAddress: string;
  registrationAddress: string;
  site: string;
  belongsToGroup: any;
  belongsEvidence: any;
  contactPhones: PhoneData[];
  taxSystems: any[];
  managementName: string;
  registrationDate: string;
}
interface PhoneData {
  id: number;
  contactName: string;
  phoneNumber: string;
  userId: number;
}
