<div class="button-bar">
  <button
    pButton
    routerLink="/new"
    routerLinkActive="active-tab"
    *ngIf="this.menuService.curMenuState == 2">
    <svg>
      <use xlink:href="./assets/img/header-menu-icons.svg#require-processing"/>
    </svg>
    <span class="button-label">Требует обработки</span>
  </button>
  <button
    pButton
    routerLink="/handled"
    routerLinkActive="active-tab"
    *ngIf="this.menuService.curMenuState == 2">
    <svg>
      <use xlink:href="./assets/img/header-menu-icons.svg#processed"></use>
    </svg>
    <span class="button-label">Обработано</span>
  </button>


  <button pButton routerLink="/shipped_weight"  routerLinkActive='active-tab'
          *ngIf="this.menuService.curMenuState == 1">
    <svg>
      <use xlink:href="./assets/img/header-menu-icons.svg#weight"/>
    </svg>
    <span class="button-label">Вес отгруженного</span>
  </button>
  <button pButton routerLink="/forward_orders"  routerLinkActive='active-tab'
          *ngIf="this.menuService.curMenuState == 1">
    <svg>
      <use xlink:href="./assets/img/header-menu-icons.svg#unclaimed-orders"/>
    </svg>
    <span class="button-label">Заявки на экспедированние</span>
  </button>
  <button pButton routerLink="/forward_settings"  routerLinkActive='active-tab'
          *ngIf="this.menuService.curMenuState == 1">
    <svg>
      <use xlink:href="./assets/img/header-menu-icons.svg#settings"/>
    </svg>
    <span class="button-label">Настройки экспедированние</span>
  </button>
  <!-- <button pButton routerLink="/transportation_tariffs"  routerLinkActive='active-tab'
          *ngIf="this.menuService.curMenuState == 1">
    <svg>
      <use xlink:href="./assets/img/header-menu-icons.svg#tariffs"/>
    </svg>
    <span class="button-label">Тарифы перевозок</span>
  </button> -->
</div>