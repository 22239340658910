import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { TransportationTariffsService } from '../../services/transportation-tariffs.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'forward-settings',
  templateUrl: './forward-settings.component.html',
  styleUrls: ['./forward-settings.component.scss'],
})
export class ForwardSettingsComponent implements OnInit, OnDestroy {
  private notifier = new Subject();
  public fee: number = 0;
  public days: number = 0;
  isUpdating: boolean = false;

  constructor(
    private router: Router,
    private trService: TransportationTariffsService,
    private primeMessageService: MessageService
  ) { }

  ngOnInit(): void {
    this.loadForwardingDefaultSettings();
  }

  ngOnDestroy() {
    this.notifier.next(false);
    this.notifier.complete();
  }

  private loadForwardingDefaultSettings() {
    this.trService.getDefaultConfigValues().subscribe((x => {
      if (x.success) {
        this.fee = parseFloat(x.data.properties.defaultForwarderFee);
        this.days = parseInt(x.data.properties.notificationIntervalDays)
      }
    }))
  }

  public updateSettings(): void {
    this.isUpdating = true;
    this.trService.updateDefaultConfigValues(this.days.toString(), this.fee.toString()).subscribe(x => {
      this.primeMessageService.add({
        severity: 'success',
        summary: 'Изменения успешно сохранены',
        detail: 'Новые данные успешно сохранены в системе'
      });
      this.isUpdating = false;
    })
  }
}
