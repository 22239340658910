import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import {
  CompanyCardResultDto,
  DocumentsAPIResponseModel
} from '../models/newDocument.model';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class RestApiService {
  apiURL = '';

  constructor(private http: HttpClient, protected config: ConfigService) {
    this.apiURL = config.baseRestURL;
  }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  getOpenInfo(package_id: number) {
    return this.http
      .get<Blob>(`${this.apiURL}openinfo/v1/moderator/${package_id}`, {
        observe: 'response',
        responseType: 'blob' as 'json'
      })
      .pipe(retry(1), catchError(this.handleError));
  }

  getUserInfo(): Observable<void> {
    return this.http
      .get<any>(`${this.apiURL}personal/main`)
      .pipe(retry(1), catchError(this.handleError));
  }

  requestsNew(
    offset: number,
    limit: number,
    types: string[]
  ): Observable<DocumentsAPIResponseModel> {
    return this.http
      .get<any>(`${this.apiURL}moderation/requests/new`, {
        params: { offset, limit, types: types.join(',') }
      })
      .pipe(retry(1), catchError(this.handleError));
  }

  requestsCountNew(
    offset: number,
    limit: number,
    types: string[]
  ): Observable<any> {
    return this.http
      .get<any>(`${this.apiURL}moderation/requests/new/count`, {
        params: { offset, limit, types: types.join(',') }
      })
      .pipe(retry(1), catchError(this.handleError));
  }

  requestsHandled(
    offset: number,
    limit: number,
    types: string[],
    filter: string = ''
  ): Observable<DocumentsAPIResponseModel> {
    return this.http
      .get<any>(`${this.apiURL}moderation/requests/handled`, {
        params: { offset, limit, types: types.join(','), filter }
      })
      .pipe(retry(1), catchError(this.handleError));
  }

  requestsCountHandled(
    offset: number,
    limit: number,
    types: string[],
    filter: string = ''
  ): Observable<any> {
    return this.http
      .get<any>(`${this.apiURL}moderation/requests/handled/count`, {
        params: { offset, limit, types: types.join(','), filter }
      })
      .pipe(retry(1), catchError(this.handleError));
  }

  requestBackcall(packageId: number): Observable<void> {
    return this.http
      .post<any>(
        `${this.apiURL}moderation/send/request/backcall`,
        JSON.stringify(packageId),
        this.httpOptions
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  requestAccreditate(phone: string): Observable<void> {
    return this.http
      .post<any>(
        `${this.apiURL}send/request/accreditate/document`,
        JSON.stringify(phone),
        this.httpOptions
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  downloadDoc(documentId: number) {
    return this.http
      .get<Blob>(`${this.apiURL}moder_docs/downloadDoc`, {
        observe: 'response',
        responseType: 'blob' as 'json',
        params: { documentId }
      })
      .pipe(retry(1), catchError(this.handleError));
  }

  downloadChildDoc(documentId: number, childId: number) {
    return this.http
      .get<Blob>(`${this.apiURL}moder_docs/downloadDoc`, {
        observe: 'response',
        responseType: 'blob' as 'json',
        params: { documentId, childId }
      })
      .pipe(retry(1), catchError(this.handleError));
  }

  getZip(documentId: number) {
    return this.http
      .get<Blob>(`${this.apiURL}moder_docs/viewZip`, {
        observe: 'response',
        responseType: 'blob' as 'json',
        params: { documentId }
      })
      .pipe(retry(1), catchError(this.handleError));
  }

  getPackage(packageId: number) {
    return this.http
      .get<any>(`${this.apiURL}moder_docs/package/content`, {
        params: { packageId }
      })
      .pipe(retry(1), catchError(this.handleError));
  }

  getNextPackage(currentRequestId: number) {
    return this.http
      .get<any>(`${this.apiURL}moderation/request/${currentRequestId}/next`)
      .pipe(retry(1), catchError(this.handleError));
  }

  startModeraton(requestId: number): Observable<void> {
    return this.http
      .post<any>(
        `${this.apiURL}moderation/request/${requestId}/start`,
        this.httpOptions
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  finishModeraton(requestId: number): Observable<void> {
    return this.http
      .post<any>(
        `${this.apiURL}moderation/request/${requestId}/finish`,
        this.httpOptions
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  getCompanyInfo(userId: number): Observable<CompanyCardResultDto> {
    return this.http
      .get<any>(`${this.apiURL}personal/contactInfo`, { params: { userId } })
      .pipe(retry(1), catchError(this.handleError));
  }

  approve(documentId: number, requestId: number): Observable<any> {
    return this.http
      .post<any>(
        `${this.apiURL}moderation/document/approve`,
        { documentId, requestId },
        this.httpOptions
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  reject(
    documentId: number,
    requestId: number,
    comment: string
  ): Observable<void> {
    return this.http
      .post<any>(
        `${this.apiURL}moderation/document/reject`,
        { documentId, requestId, comment },
        this.httpOptions
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  postpone(
    documentId: number,
    requestId: number,
    comment: string
  ): Observable<void> {
    return this.http
      .post<any>(
        `${this.apiURL}moderation/document/postpone`,
        { documentId, requestId, comment },
        this.httpOptions
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  // eslint-disable-next-line class-methods-use-this
  private handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(() => errorMessage);
  }
}
