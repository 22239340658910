import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { debounceTime, first, Observable, Subject, takeUntil } from 'rxjs';
import { DocumentsService } from 'src/app/services/documents.service';
import { Router } from '@angular/router';
import { RestApiService } from '../../services/api.service';
import { DocumentInterace, DocumentsAPIResponseModel } from '../../models/newDocument.model';

@Component({
  selector: 'd1861-app-approved-items-list',
  templateUrl: './approved-items-list.component.html',
  styleUrls: ['./approved-items-list.component.scss']
})
export class ApprovedItemsListComponent implements OnInit, OnDestroy {
  public items$!: Observable<DocumentsAPIResponseModel>;

  public form!: FormGroup;

  public paginationSettings = {
    currentPage: 0,
    rows: 10,
    rowsPerPageOptions: [10, 20, 30]
  };

  public itemsCount$!: Observable<any>;

  private requestTypes: string[] = ['ACCREDITATE_PACKAGE'];

  private notifier = new Subject();

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private apiService: RestApiService,
    private docService: DocumentsService
  ) { }

  ngOnInit(): void {
    this.initForm();
    this.items$ = this.getData(
      this.paginationSettings.currentPage,
      this.paginationSettings.rows
    );
    this.itemsCount$ = this.getCountData(
      this.paginationSettings.currentPage,
      this.paginationSettings.rows
    );
  }

  public navigate(paramItem: DocumentInterace) {
    let item = paramItem;
    if (item.type === 'ACCREDITATE_DOCUMENT') {
      const packageInfoObj = {
        packageInfo: {
          id: item.documentInfo.packageId,
          name: ''
        }
      };
      item = { ...paramItem, ...packageInfoObj };
      item.id = item.documentInfo.id;
      this.docService.setDoc(item);
      this.router.navigate(['/detailed-list'], { queryParams: {
        docId: item.documentInfo?.id,
        handled: true,
      } });
    }
    if (item.type === 'ACCREDITATE_PACKAGE') {
      this.docService.setDoc(item);
      this.router.navigate(['/detailed-list'], { queryParams: {
        handled: true,
      } });
    }
  }

  public paginate(event: any) {
    this.items$ = this.getData(
      event.page * event.rows,
      event.rows
    );
  }

  private getData(currentPage: number, rows: number): Observable<DocumentsAPIResponseModel> {
    return this.apiService.requestsHandled(
      currentPage,
      rows,
      this.requestTypes,
      this.form.controls['searchInput'].value
    ).pipe(first());
  }

  private getCountData(currentPage: number, rows: number): Observable<void> {
    return this.apiService.requestsCountHandled(
      currentPage,
      rows,
      this.requestTypes,
      this.form.controls['searchInput'].value
    ).pipe(first());
  }

  private initForm() {
    this.form = this.fb.group({
      documentTypesDocument: 'ACCREDITATE_DOCUMENT',
      documentTypesPackage: 'ACCREDITATE_PACKAGE',
      searchInput: ''
    });

    this.form.valueChanges.pipe(takeUntil(this.notifier), debounceTime(500)).subscribe(() => {
      this.requestTypes = [];
      if (this.form.controls['documentTypesPackage'].value) {
        this.requestTypes.push('ACCREDITATE_PACKAGE');
      }
      if (this.form.controls['documentTypesDocument'].value) {
        this.requestTypes.push('ACCREDITATE_DOCUMENT');
      }
      this.itemsCount$ = this.getCountData(
        this.paginationSettings.currentPage,
        this.paginationSettings.rows
      );

      this.items$ = this.getData(
        this.paginationSettings.currentPage,
        this.paginationSettings.rows
      );
    });
  }

  ngOnDestroy() {
    this.notifier.next(false);
    this.notifier.complete();
  }
}
