import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ApprovedItemsListComponent } from './components/approved-items-list/approved-items-list.component';
import { DetailedListComponent } from './components/detailed-list/detailed-list.component';
import { NewItemsListComponent } from './components/new-items-list/new-items-list.component';
import { AppAuthGuard } from './d1861-utils/appAuthGuard';
import { ConfirmLeaveGuard } from './d1861-utils/confirm-leave-guard';
import { LoginComponent } from './components/login/login.component';
import { LoginGuard } from './d1861-utils/loginGuard';
import { TransportationTariffsComponent } from './components/transportation-tariffs/transportation-tariffs.component';
import { ShippedWeightComponent } from './components/shipped-weight/shipped-weight.component';
import { ForwardOrdersComponent } from './components/forward-orders/forward-orders.component';
import { ForwardSettingsComponent } from './components/forward-settings/forward-settings.component';

const routes: Routes = [

  {
    path: 'handled',
    component: ApprovedItemsListComponent,
    canActivate: [AppAuthGuard]
  },
  {
    path: 'new',
    component: NewItemsListComponent,
    canActivate: [AppAuthGuard]
  },
  {
    path: 'detailed-list',
    component: DetailedListComponent,
    canDeactivate: [ConfirmLeaveGuard],
    canActivate: [AppAuthGuard]
  },
  {
    path: '',
    redirectTo: '/shipped_weight',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [LoginGuard]
  },
  // {
  //   path: 'transportation_tariffs',
  //   component: TransportationTariffsComponent,
  //   canActivate: [AppAuthGuard]
  // },
  {
    path: 'shipped_weight',
    component: ShippedWeightComponent,
    canActivate: [AppAuthGuard]
  },
  {
    path: 'forward_orders',
    component: ForwardOrdersComponent,
    canActivate: [AppAuthGuard]
  },
  {
    path: 'forward_settings',
    component: ForwardSettingsComponent,
    canActivate: [AppAuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  providers: [AppAuthGuard, ConfirmLeaveGuard, LoginGuard],
  exports: [RouterModule]
})
export class AppRoutingModule {}
