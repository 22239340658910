<!-- <div [style.display]="path !== '/login' ? 'block' : 'none'">
  <d1861-header></d1861-header>
  <div class="content">
    <d1861-sidebar
      *ngIf="path === '/new' || path === '/handled'"
    ></d1861-sidebar>
    <router-outlet></router-outlet>
  </div>
</div> -->
<d1861-header *ngIf="path !== '/login'"></d1861-header>
<div class="content">
  <router-outlet></router-outlet>
</div>
<p-toast closable="false">
  <ng-template let-message pTemplate="message">
    <div class="flex flex-column" style="flex: 0">
      <img class="toast-icon" *ngIf="'success' === message.severity" src="../assets/messageStatus/Ok.svg" alt="Ок" />
      <img class="toast-icon" *ngIf="'info' === message.severity" src="../assets/messageStatus/Warning.svg"
        alt="Предупреждение" />
      <img class="toast-icon" *ngIf="'error' === message.severity" src="../assets/messageStatus/Refused.svg"
        alt="Ошибка" />
    </div>
    <div class="flex flex-column" style="flex: 1">
      <div>
        <div class="toast-header" [innerHTML]="message.summary"></div>
        <div class="toast-body" [innerHTML]="message.detail"></div>
      </div>
    </div>
  </ng-template>
</p-toast>
