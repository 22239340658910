<h3>Необработанные документы</h3>
<h4>Количество документов: {{(itemsCount$ | async)?.data.count}}</h4>
<d1861-button
*ngIf="refreshButtonShown$ | async"
[icon]="'../../../assets/img/warning.svg'"
[text]="'Обновить список пакетов'"
(click)="refreshList()"
></d1861-button>
<div class="container">
  <ng-container *ngIf="(itemsCount$ | async)?.data.count as count">
    <p-paginator 
      [rows]="paginationSettings.rows"
      [totalRecords]="count"
      [rowsPerPageOptions]="paginationSettings.rowsPerPageOptions" 
      (onPageChange)="paginate($event)">
    </p-paginator>
  </ng-container>
  <ng-container *ngIf="(itemsNew$ | async)?.data as items; else loading">
    <d1861-package-list-item
      *ngIf="items.length; else no_content"
      (clickEvent)="navigate($event)"
      [items]="items">
    </d1861-package-list-item>
  </ng-container>
</div>
<ng-template #loading>
  <p-progressSpinner></p-progressSpinner>
</ng-template>
<ng-template #no_content>
  <div class="no-data">Пакетов на обработку нет</div>
</ng-template>