import { Component } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { NavigationEnd, Router } from '@angular/router';
import { KeycloakService } from 'src/app/services/keycloak.service';
import { Location } from '@angular/common';
import { MenuService } from '../../services/menu.service';


@Component({
  selector: 'd1861-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  items: MenuItem[] = [];

  public isLoggedIn: boolean = false;

  public arrowRotation: boolean = false;

  public userProfile: any | null = null;

  public displayMessages: boolean = false;


  public path!: string;
  constructor(private router: Router,
              private location: Location,
              private readonly keycloak: KeycloakService,
              public menuService: MenuService) {
    // this.isLoggedIn$ = from(this.keycloak.isLoggedIn());

    this.router.events
      /*    .pipe(takeUntil(this.destroy$))*/
      .subscribe((event: any) => {
        if (event instanceof NavigationEnd) {
          this.path = this.location.path();
        }
      });
  }

  public async ngOnInit() {
    this.isLoggedIn = await this.keycloak.isLoggedIn();
    if (this.isLoggedIn) {
      this.userProfile = await this.keycloak.loadUserProfile();
    }
    this.items = [
      {
        label: 'Выйти',
        icon: 'pi pi-sign-out',
        command: () => {
          this.logout();
        }
      }
    ];
  }

  logout() {
    this.keycloak.logout();
  }

  onEvent() {
    this.arrowRotation = true;
  }

  backToHomePage(): void {
    this.router.navigate(['new']);
  }

}
