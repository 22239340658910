<div class="page-block">
  <div class="page-header">
    <div>
      <h1>Вес отгруженного</h1>
    </div>
  </div>
  <div class="wrapper" *ngIf="freightList.length && !isLoading">
    <div class="card" *ngFor="let item of freightList">
      <div class="order-info">
        <div class="row gap16">
          <div class="order-number">
            № <strong>{{item.id}}</strong>
          </div>
          <div class="vertical-line"></div>
          <div class="contragents">
            <div>{{item.seller.name}}</div>
            <div class="inn">{{item.seller.inn}}</div>
            <img src="../../assets/arrow-to-right.svg">
            <div>{{item.buyer.name}}</div>
            <div class="inn">{{item.buyer.inn}}</div>
          </div>
        </div>

        <div class="filter-block">
          <div class="filter-block_sort">
            <div class="sort-wrapper">
              <div class="filter-label">Вес отгруженного:</div>
              <div class="flex-1">
                <div class="p-inputgroup">
                  <p-inputNumber class="bold-text" [(ngModel)]="item.curWeightKg" mode="decimal" [maxFractionDigits]="0"
                    placeholder="----"> </p-inputNumber>
                  <span class="p-inputgroup-addon">кг</span>
                </div>

              </div>
              <d1861-button [disabled]="!item.curWeightKg" (clickFunc)="updateWeight(item)" [smallButton]="true"
                [disabled]="isUpdating && updatedCardIds.includes(item.id)"
                [ngClass]="(isUpdating && updatedCardIds.includes(item.id)) ? 'looping' : ''"
                [icon]="(isUpdating && updatedCardIds.includes(item.id)) ? '../../assets/process.svg' : '../../assets/round-green-check.svg'"></d1861-button>
            </div>
          </div>
        </div>
      </div>
      <hr>
      <div class="good-info">
        <img [src]="'../../assets/img/goods/' + imgService.getIconName(item.goodsType) + '.svg'">
        <div class="table">
          <div class="column">
            <div class="field">Культура:</div>
            <div class="value bold-text">
              {{item.goodsType}}
            </div>
          </div>
          <div class="vertical-line"></div>
          <div class="column no-wrap">
            <div class="field">Год:</div>
            <div class="gray">
              <span class="bold-text">{{item.cropYear}}</span>
            </div>
          </div>
          <div class="vertical-line"></div>
          <div class="column no-wrap">
            <div class="field">Вес:</div>
            <div class="gray">
              <span class="bold-text">{{item.goodsWeight || 0 | number:'':'ru-RU'}}</span> т
            </div>
          </div>
          <div class="vertical-line"></div>
          <div class="column">
            <div class="field">Дата погрузки:</div>
            <div class="value">
              с {{item.forwardingStart}} по {{item.forwardingEnd}}
            </div>
          </div>
          <div class="vertical-line"></div>
          <div class="column">
            <div class="field">Точка погрузки:</div>
            <div class="column gap8">
              <div class="address-title">{{item.fromAddressTitle}}</div>
              <div class="address">{{item.fromAddress}}</div>
            </div>
          </div>
          <div class="vertical-line"></div>
          <div class="column">
            <div class="field">Точка выгрузки:</div>
            <div class="address-title">{{item.toAddressTitle}}</div>
            <div class="address">{{item.toAddress}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="wrapper" *ngIf="isLoading">
    <p-skeleton class="offer-skeleton"></p-skeleton>
    <p-skeleton class="offer-skeleton"></p-skeleton>
  </div>

  <div class="no-results" *ngIf="!freightList.length && !isLoading">
    Ничего не найдено
  </div>
</div>