import { Component, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { of, Subject, Observable, takeUntil } from 'rxjs';
import { Location } from '@angular/common';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy {
  public sideBarDisabled$: any = false;

  public isLoggedIn$: Observable<boolean> = of(false);

  public path!: string;

  private notifier = new Subject();

  private destroy$ = new Subject();

  constructor(private router: Router, private location: Location) {
    // this.isLoggedIn$ = from(this.keycloak.isLoggedIn());

    this.router.events
      .pipe(takeUntil(this.destroy$))
      .subscribe((event: any) => {
        if (event instanceof NavigationEnd) {
          this.path = this.location.path();
        }
      });
  }

  ngOnDestroy() {
    this.notifier.next(false);
    this.notifier.complete();

    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
