import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { TransportationTariffsService } from '../../services/transportation-tariffs.service';
import { Region, RegionResponse, Tariff, TariffResponse } from '../../models/transportTariffs.model';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'transportation-tariffs',
  templateUrl: './transportation-tariffs.component.html',
  styleUrls: ['./transportation-tariffs.component.scss']
})
export class TransportationTariffsComponent implements OnInit, OnDestroy {
  private notifier = new Subject();
  regions: Region[] = [];
  selectedRegion: Region | undefined; // выбранный регион
  curTariffsList: Tariff[] = []; // тариф для выбранного региона
  isUpdating: boolean = false;

  constructor(
    private trService: TransportationTariffsService,
    private primeMessageService: MessageService
  ) { }

  ngOnInit(): void {
    this.loadRegions();
  }

  loadRegions(): void {
    this.trService.getRegionsList().subscribe((regResp: RegionResponse) => {
      if (regResp.success) {
        this.regions = regResp.data;
        if (this.regions && this.regions.length > 0) {
          this.selectedRegion = this.selectedRegion || this.regions[0];
          this.regionChange();
        }
      }
    });
  }

  ngOnDestroy() {
    this.notifier.next(false);
    this.notifier.complete();
  }

  regionChange() {
    if (this.selectedRegion) {
      this.trService.getTariffList(this.selectedRegion.regionId, 20, 0)
        .subscribe((tariffResponse: TariffResponse) => {
          if (tariffResponse.success) {
            this.curTariffsList = tariffResponse.data;
          }
        });
    }
  }

  saveTariffs() {
    this.isUpdating = true;
    let count = 0;
    this.curTariffsList.forEach((t: Tariff) => {
      this.trService.updateTariff(t.id, t.koef1 || 0, t.koef2 || 0).subscribe((x) => {
        count++;
        this.isUpdating = count !== this.curTariffsList.length;
        if (count === this.curTariffsList.length) {
          this.primeMessageService.add({
            severity: 'success',
            summary: 'Изменения успешно сохранены',
            detail: 'Новые данные успешно сохранены в системе'
          });
          this.loadRegions();
        }
      });
    });
  }

  restrictInput(event: KeyboardEvent) {
    if (event.key === ',' || event.key === '.') return;
    if (!((['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'].includes(event.key)) && event.key !== '-' && event.key !== 'e')) {
      event.preventDefault();
    }
  }
}
