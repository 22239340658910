import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { KeycloakService } from '../services/keycloak.service';

@Injectable()
export class AppAuthGuard implements CanActivate {
  constructor(
    protected router: Router,
    protected keycloakAngular: KeycloakService
  ) {}

  canActivate(): boolean {
    if (!this.keycloakAngular.isLoggedIn()) {
      this.keycloakAngular.logout();
      return false;
    }
    return (
      this.keycloakAngular.isLoggedIn() &&
      this.keycloakAngular.getUserRoles().includes('MODERATOR')
    );
  }
}
