import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { KeycloakService } from '../services/keycloak.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    public router: Router,
    private readonly keycloak: KeycloakService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const authRequest = req.clone({
      headers: req.headers.append('authorization', this.keycloak.getToken())
    });
    return next.handle(authRequest).pipe(
      catchError((error) => {
        switch (error.status) {
          case 401: // not authorized
            this.keycloak.logout();
            break;
          default:
            break;
        }
        return throwError(error);
      })
    );
  }
}
