<p-dialog 
  [(visible)]="display"
  [resizable]="false"
  [draggable]="false"
  [closable]="false"
  [modal]="true">
  <div class="p-dialog-container">
    <span class="pi pi-times close-icon" (click)="commentForm.reset(); closeForm()" ></span>

    <ng-container *ngIf="action === 'reject'; else postpone">
      <h3 class="reject">Отклонить документ</h3>
      <div class="description">
        Укажите причину по которой вы отклоняете данный документ *
      </div>
    </ng-container>
    <ng-template #postpone>
      <h3 class="postpone">Отложить документ</h3>
      <div class="description">
        Укажите причину по которой вы откладываете данный документ для дальнейшей модерации *
      </div>
    </ng-template>

    <form #commentForm="ngForm" [class]="action">
      <label for="textarea-input">Ваш комментарий:</label>
      <textarea 
        placeholder="Введите комментарий"
        name="comment" 
        id="comment" 
        ngModel
        rows="12"
        required></textarea>

      <d1861-button
        class="d1861-button"
        *ngIf="!commentForm.invalid; else disabledButton"
        [icon]="action === 'reject' ? '../../../assets/img/doc-rejected.svg': '../../../assets/img/warning.svg'"
        [text]="buttonText"
        (click)="submit(commentForm)"
      ></d1861-button>
      <ng-template #disabledButton>
        <d1861-button
          class="d1861-button"
          [disabled]="commentForm.invalid"
          [icon]="action === 'reject' ? '../../../assets/img/doc-rejected.svg': '../../../assets/img/warning.svg'"
          [text]="buttonText"
        ></d1861-button>
      </ng-template>
    </form>
  </div>
</p-dialog>