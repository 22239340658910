import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { DetailedListComponent } from '../components/detailed-list/detailed-list.component';
import { KeycloakService } from '../services/keycloak.service';

@Injectable()
export class ConfirmLeaveGuard implements CanDeactivate<DetailedListComponent> {
  constructor(private readonly keycloak: KeycloakService) {}

  // eslint-disable-next-line class-methods-use-this
  canDeactivate(
    component: DetailedListComponent
  ): boolean | Observable<boolean> {
    if (this.keycloak.isLoggedIn()) {
      if (!component.needActions) {
        return true;
      }
      let subject = new Subject<boolean>();
      subject = component.exitSubject;
      component.openExitDialog();
      return subject.asObservable();
    }
    return true;
  }
}
