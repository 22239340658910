import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { DocumentInterace, DocStatuses } from '../../../models/newDocument.model';
import { RestApiService } from '../../../services/api.service';

@Component({
  selector: 'd1861-package-list-item',
  templateUrl: './package-list-item.component.html',
  styleUrls: ['./package-list-item.component.scss']
})
export class PackageListItemComponent implements OnInit {
  @Input() items!: DocumentInterace[];

  @Input() handledView: boolean = false;

  @Output() clickEvent = new EventEmitter<DocumentInterace>();

  public currendId$!: Observable<any>;

  constructor(private apiService: RestApiService) {

  }

  public click(item: DocumentInterace) {
    this.clickEvent.emit(item);
  }

  public docStatuses = DocStatuses;

  ngOnInit(): void {
    this.currendId$ = this.apiService.getUserInfo();
  }
}
