<h3>Обработанные документы</h3>
<div class="container">
  <form [formGroup]="form">
    <input type="text" id="searchInput" formControlName="searchInput" placeholder="Название СХТП, ИП, ИНН, ОГРН организации"/>
  
    <!-- <p-toggleButton formControlName="documentTypesDocument" onLabel="Документы" offLabel="Документы" class="docs-toggle-button"></p-toggleButton>
    <p-toggleButton formControlName="documentTypesPackage" onLabel="Пакеты" offLabel="Пакеты" class="docs-toggle-button"></p-toggleButton> -->

  </form>
  <ng-container *ngIf="(itemsCount$ | async)?.data.count as count">
    <p-paginator 
      [rows]="paginationSettings.rows"
      [totalRecords]="count"
      [rowsPerPageOptions]="paginationSettings.rowsPerPageOptions" 
      (onPageChange)="paginate($event)">
    </p-paginator>
  </ng-container>
  <ng-container *ngIf="(items$ | async)?.data as items; else loading">
    <d1861-package-list-item
      *ngIf="items.length; else no_content"
      (clickEvent)="navigate($event)"
      [handledView]="true"
      [items]="items">
    </d1861-package-list-item>
  </ng-container>
</div>

<ng-template #loading>
  <div class="no-data">
    <p-progressSpinner></p-progressSpinner>
    Загрузка данных
  </div>
</ng-template>
<ng-template #no_content>
  <div class="no-data">Нет данных для отображения</div>
</ng-template>