import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { ConfigService } from './config.service';
import { MenuService } from './menu.service';

export interface APITokenDto {
  token: string;
}
export interface JWTToken {
  exp: number;
  iat: number;
  auth_time: number;
  jti: string;
  iss: string;
  aud: string;
  sub: string;
  typ: string;
  azp: string;
  nonce: string;
  session_state: string;
  acr: string;
  realm_access: {
    roles: string[];
  };
  resource_access: {
    account: {
      roles: string[];
    };
  };
  scope: string;
  sid: string;
  email_verified: boolean;
  name: string;
  preferred_username: string;
  given_name: string;
  family_name: string;
  email: string;
}

@Injectable({
  providedIn: 'root'
})
export class KeycloakService {
  private baseRestURL!: string;

  constructor(
    protected http: HttpClient,
    protected config: ConfigService,
    private router: Router,
    public menuService: MenuService
  ) {
    this.baseRestURL = config.baseRestURL;
  }

  public feedbackToggleEvent: Subject<any> = new Subject();

  private refreshTokenTimeout: any;

  public isLoggedIn(): boolean {
    return !!this.getToken();
  }

  public login(token: string, refreshToken?: string, ttl?: number) {
    this.saveToken(token);
    if (refreshToken && ttl) {
      this.startRefreshTokenTimer();
      this.saveRefreshToken(refreshToken, ttl);
      this.menuService.curMenuState = 1;
      this.router.navigate(['shipped_weight']);// Тут
    }
  }

  public logout() {
    localStorage.setItem('token', '');
    localStorage.setItem('refresh-token', '');
    localStorage.setItem('ttl', '');
    this.stopRefreshTokenTimer();
    this.router.navigate(['login']);
  }

  public updatePassword(email: string) {
    const params = new HttpParams().set('email', email);

    return this.http.post<any>(
      `${this.baseRestURL}/usermanagement/registration/reset-password`,
      {},
      { params, observe: 'response' }
    );
  }

  public verifyEmail(id: string): Observable<any> {
    const curUrl = `${this.baseRestURL}/usermanagement/registration/verify-email/${id}`;
    return this.http.post<any>(curUrl, {});
  }

  public verifyInvitationEmail(id: string): Observable<any> {
    const curUrl = `${this.baseRestURL}/usermanagement/invitation/verify-email/${id}`;
    return this.http.post<any>(curUrl, {});
  }

  public refreshToken(): any {
    const refreshToken = this.getRefreshToken();
    if (refreshToken) {
      const curUrl = `${this.baseRestURL}/usermanagement/registration/refresh-token?refreshToken=${refreshToken}`;
      this.http.post<any>(curUrl, {}).subscribe((response: any) => {
        this.saveToken(response.data.access_token);
        this.saveRefreshToken(
          response.data.refresh_token,
          response.data.expires_in
        );
        this.startRefreshTokenTimer();
      });
    }
  }

  public getUserRoles() {
    const token = this.parseJwt(this.getToken());
    if (token) {
      return token.realm_access.roles;
    }
    return [];
  }

  public checkRoleForLogin(token: string) {
    const parsedToken = this.parseJwt(token);
    if (parsedToken) {
      return parsedToken.realm_access.roles;
    }
    return [];
  }

  public loadUserProfile() {
    const token = this.parseJwt(this.getToken());
    return {
      id: token?.sid,
      username: token?.preferred_username,
      email: token?.email,
      firstName: token?.name,
      lastName: token?.family_name,
      emailVerified: token?.email_verified
    };
  }

  private saveToken(token: string): void {
    localStorage.setItem('token', `Bearer ${token}`);
  }

  private saveRefreshToken(token: string, ttl?: number): void {
    localStorage.setItem('refresh-token', `${token}`);
    localStorage.setItem('ttl', `${ttl}`);
  }

  public getRefreshToken() {
    const refreshToken = localStorage.getItem('refresh-token') || '';
    return refreshToken;
  }

  public getRefreshTokenTtl(): number {
    return parseInt(localStorage.getItem('ttl') || '0', 10);
  }

  public getToken(): string {
    return localStorage.getItem('token') || '';
  }

  private parseJwt(token: string | null): JWTToken | null {
    if (!token) {
      return null;
    }
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
        .join('')
    );
    return JSON.parse(jsonPayload);
  }

  public startRefreshTokenTimer() {
    const jwtToken = this.parseJwt(this.getToken());

    if (jwtToken) {
      // eslint-disable-next-line no-unsafe-optional-chaining
      const expires = new Date(jwtToken?.exp * 1000);
      const timeout = expires.getTime() - Date.now() - 60 * 1000;
      this.refreshTokenTimeout = setTimeout(() => this.refreshToken(), timeout);
    }
  }

  private stopRefreshTokenTimer() {
    clearTimeout(this.refreshTokenTimeout);
  }
}
