<p-dialog 
  [(visible)]="display"
  [resizable]="false"
  [draggable]="false"
  [closable]="false"
  [modal]="true">
  <div class="p-dialog-container">
    <h3>Результат автоматической премодерации</h3>
    <div class="warning-section">
      Документ отклонен
    </div>

    Автоматическая премодерация не является основанием для принятия решения в отношении статуса документа. Необходима ручная проверка! 

    <div class="buttons-section">
      <button 
        pButton
        class="p-button-raised p-button-secondary continue"
        type="button" 
        label="Продолжить обработку" 
        (click)="continue()">
      </button>
    </div>
  </div>
</p-dialog>