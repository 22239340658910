import { Component } from '@angular/core';
import { MenuService } from '../../services/menu.service';


@Component({
  selector: 'd1861-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {
  constructor( public menuService: MenuService) {

  }
}
