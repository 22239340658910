import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Freight } from 'src/app/models/transportTariffs.model';
import { TransportationTariffsService } from 'src/app/services/transportation-tariffs.service';
import { ImgService } from 'src/app/services/img.service';
import { MessageService } from 'primeng/api';


@Component({
  selector: 'offer',
  templateUrl: './offer.component.html',
  styleUrls: ['./offer.component.scss'],
})
export class OfferComponent {
  @Input() order!: Freight;
  @Output() reload: EventEmitter<boolean> = new EventEmitter();
  isUpdating: boolean = false;

  constructor(
    public imgService: ImgService,
    private trService: TransportationTariffsService,
    private primeMessageService: MessageService,
  ) { }


  updateValue(order: Freight) {
    this.isUpdating = true;
    this.trService.updateRequestForwarderFee(order.id, order.forwarderFee).subscribe(() => {
      this.reload.next(true);
      this.isUpdating = false;
      this.primeMessageService.add({
        severity: 'success',
        summary: 'Изменения успешно сохранены',
        detail: 'Новые данные успешно сохранены в системе'
      });
    });
  }
}
