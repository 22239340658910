import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'd1861-premoderation-result-dialog',
  templateUrl: './premoderation-result-dialog.component.html',
  styleUrls: ['./premoderation-result-dialog.component.scss']
})
export class PremoderationResultDialogComponent {
  @Input() display!: boolean;

  @Output() continueEvent = new EventEmitter<string>();

  public continue() {
    this.continueEvent.emit('continue');
  }
}
