import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ImgService {

  public getIconName(name: string) {
    const iconsName: any = {
      Горох: 'pea',
      Кукуруза: 'corn',
      Лён: 'linen',
      Ячмень: 'barley',
      Рожь: 'rye',
      Подсолнечник: 'sunflower'
    };

    const defaultIcon = 'wheat';

    return iconsName[name] || defaultIcon;
  }
}
