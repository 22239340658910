import { Component, Input, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {
  DocReadness,
  DocumentInterace
} from 'src/app/models/newDocument.model';
import { DocumentsService } from 'src/app/services/documents.service';

@Component({
  selector: 'd1861-approve-dialog',
  templateUrl: './approve-dialog.component.html',
  styleUrls: ['./approve-dialog.component.scss']
})
export class ApproveDialogComponent {
  @Input() display!: boolean;

  @Output() continueEvent = new EventEmitter<string>();

  @Output() nextPackageEvent = new EventEmitter<string>();

  @Output() exitEvent = new EventEmitter<string>();

  public packageName: string | undefined = '';

  public packageUser$: BehaviorSubject<DocumentInterace>;

  public packageStatus$: BehaviorSubject<DocReadness>;

  constructor(private docService: DocumentsService) {
    // все документы this.docService.getProfileObs().subscribe(a => console.warn(a));
    this.packageName = this.docService.getDoc().packageInfo.name;

    this.packageUser$ = this.docService.packageUser$;

    this.packageStatus$ = this.docService.docReadness$;
  }

  public continue() {
    this.continueEvent.emit('continue');
  }

  public exit() {
    this.exitEvent.emit('exit');
  }

  public next() {
    this.nextPackageEvent.emit('next');
  }
}
