<div
  class="header-detailed"
  [ngClass]="{ 'not-actual': !(items$ | async)?.data?.isActualDocsList }"
>
  <div class="package_owner" *ngIf="packageUser">
    <span class="owner-name">{{ packageUser.name }}</span>
    <span class="owner-info">
      ИНН: {{ packageUser.inn }}&nbsp;
      {{ packageUser.address }}
    </span>
  </div>
  <div
    class="not-actual-wrapper"
    *ngIf="!(items$ | async)?.data?.isActualDocsList"
  >
    <img src="../../../assets/img/warning.svg" />
    <div class="not-actual-text">
      <span>Внимание! Список основных документов изменился!</span>
      <span
        >Список документов в пакете не соответствует актуальным
        требованиям</span
      >
    </div>
  </div>

  <d1861-button
    [text]="'Вернуться к списку документов'"
    [icon]="'../../../assets/img/ok.svg'"
    (click)="showExitWindow()"
  >
  </d1861-button>
</div>
<div class="detailed-content">
  <ng-container *ngIf="(items$ | async)?.data?.content as items">
    <div class="button-bar">
      <div
        class="item"
        (click)="getCompanyInfo(); selectedItemIndx = -2"
        [ngClass]="{ selected: selectedItemIndx === -2 }"
      >
        Информация о компании
      </div>
      <div
        class="item"
        (click)="getOpenInfo(); selectedItemIndx = -1"
        [ngClass]="{ selected: selectedItemIndx === -1 }"
      >
        Открытая информация
      </div>
      <div
        class="item"
        *ngFor="let item of items; let i = index"
        (click)="getDocument(item, i)"
        [ngClass]="{
          selected: i === selectedItemIndx,
          refused: item.isRefusedByUser,
          'refused-with-status': item.status !== 'SENT_TO_MODERATOR'
        }"
      >
        <div class="doc-name-wrapper">
          <span class="doc-name">{{ item.name || item.role.name }}</span>
          <span *ngIf="item.isExpired" class="doc-status-expired"
            >Закончился срок действия документа!
            <span class="expired-date"
              >До:
              <span>{{ item.actualDateTo | date: 'dd.MM.yyyy' }} </span></span
            >
          </span>
          <span *ngIf="item.isExpiredSoon" class="doc-status-expired-soon"
            >Заканчивается срок действия документа!
            <span class="expired-date"
              >До:
              <span>{{ item.actualDateTo | date: 'dd.MM.yyyy' }} </span></span
            >
          </span>

          <span
            *ngIf="item.actualDateTo && !item.isExpired && !item.isExpiredSoon"
            class="doc-status-date-to"
          >
            Действительно до:
            <span>{{ item.actualDateTo | date: 'dd.MM.yyyy' }}</span>
          </span>
          <span
            *ngIf="item.experationType === 'PERPETUAL'"
            class="doc-status-date-to"
            >Бессрочный документ</span
          >
        </div>
        <span class="doc-status" [class]="item.status"></span>
      </div>
    </div>
    <div class="doc-preview" [ngClass]="{ 'full-size': fullSize }">
      <div
        *ngIf="preModerationFailed"
        class="premoderation-button"
        (click)="isPreModerationResultShown = !isPreModerationResultShown"
      ></div>
      <div class="viewer-container">
        <div class="overlay" *ngIf="this.docLoading">
          <p-progressSpinner></p-progressSpinner>
        </div>
        <div class="overlay" *ngIf="this.loadingError">
          <div *ngIf="isRefusedByUser; else commonMessage">
            <span
              >Пользователь отказался от предоставления документа по
              причине:</span
            >
            <span>{{ selectedDoc.userComment }}</span>
          </div>
          <ng-template #commonMessage>
            <span *ngIf="!this.docLoading">Ошибка загрузки документа</span>
          </ng-template>
        </div>
        <div
          class="moderation-status"
          *ngIf="
            selectedDoc?.status === 'REJECTED' ||
            selectedDoc?.status === 'POSTPONED' ||
            selectedDoc?.status === 'APPROVED'
          "
          [class]="selectedDoc.status"
        >
          <div class="doc-status" [ngSwitch]="selectedDoc.status">
            <ng-template ngSwitchCase="APPROVED"
              >Документ одобрен модератором:
              {{ selectedDoc.moderatorName }}</ng-template
            >
            <ng-template ngSwitchCase="REJECTED"
              >Документ отклонён модератором:
              {{ selectedDoc.moderatorName }}</ng-template
            >
            <ng-template ngSwitchCase="POSTPONED"
              >Документ отложен модератором:
              {{ selectedDoc.moderatorName }}</ng-template
            >
          </div>
          <div class="comment" *ngIf="selectedDoc.status !== 'APPROVED'">
            <div
              *ngIf="selectedDoc.moderatorComment"
              class="comment-text-container"
              (click)="expandedComment = !expandedComment"
              [ngClass]="{ expanded: expandedComment }"
            >
              <div class="comment-text">
                {{ selectedDoc.moderatorComment }}
              </div>
            </div>
          </div>
        </div>

        <div
          class="commom-view-container"
          [ngClass]="{
            'no-status':
              selectedDoc &&
              (!selectedDoc.status ||
                selectedDoc.status === 'SENT_TO_MODERATOR')
          }"
        >
          <div
            class="viewer-zoom-toolbar"
            *ngIf="
              !this.docLoading &&
              !this.loadingError &&
              selectedDocType !== DocTypes['html']
            "
            cdkDrag
          >
            <div
              class="zoom-out-btn"
              (click)="zoom_out()"
              [ngClass]="{ disabled: pdfSettings.zoom_to === 0.25 }"
            >
              -
            </div>
            <div
              class="zoom-in-btn"
              (click)="zoom_in()"
              [ngClass]="{ disabled: pdfSettings.zoom_to === 2 }"
            >
              +
            </div>
            <div class="zoom-ratio">{{ pdfSettings.zoom_to * 100 }} %</div>
          </div>

          <div
            [hidden]="selectedDocType !== DocTypes['pdf']"
            class="pdf-viewer"
          >
            <pdf-viewer
              [src]="srcLink"
              #docPreview
              [render-text]="true"
              [show-borders]="true"
              [(page)]="pdfSettings.page"
              (after-load-complete)="afterLoadComplete($event)"
              [zoom]="pdfSettings.zoom_to"
              style="display: block"
            >
            </pdf-viewer>
            <div class="pdf-viewer-pages-toolbar">
              <div
                class="pdf-next-page"
                (click)="pdfNextPage()"
                [ngClass]="{
                  disabled: pdfSettings.page === this.pdfSettings.totalPages
                }"
              ></div>
              <div
                class="pdf-prev-page"
                (click)="pdfPrevPage()"
                [ngClass]="{ disabled: pdfSettings.page === 1 }"
              ></div>
              <div class="pdf-page-number">
                {{ pdfSettings.page }} из {{ pdfSettings.totalPages }}
              </div>
            </div>
          </div>

          <d1861-html-preview
            *ngIf="selectedDocType === DocTypes['html']"
            [data]="itemHtml$ | async"
          ></d1861-html-preview>

          <div
            #imgPreview
            (scroll)="onScroll($event)"
            class="img-preview-div"
            [hidden]="
              selectedDocType !== DocTypes['image'] ||
              this.docLoading ||
              this.loadingError
            "
          ></div>
          <div
            class="img-viewer-pages-toolbar"
            *ngIf="selectedDocType === DocTypes['image']"
          >
            <div
              class="img-next-page"
              (click)="imgNextPage()"
              [ngClass]="{
                disabled: imgSettings.page === this.imgSettings.totalPages - 1
              }"
            ></div>
            <div
              class="img-prev-page"
              (click)="imgPrevPage()"
              [ngClass]="{ disabled: imgSettings.page === 0 }"
            ></div>
            <div class="img-page-number">
              {{ imgSettings.page + 1 }} из {{ imgSettings.totalPages }}
            </div>
          </div>
        </div>
      </div>
      <div class="doc-panel">
        <div class="docs-info">
          <span>{{ (items$ | async)?.data?.description }}</span>
          <span
            >{{ selectedItemIndx + 3 }} документ из {{ items.length + 2 }}</span
          >
        </div>
        <div class="buttons-container">
          <d1861-button
            [ngClass]="{
              'disabled-event': docLoading || selectedItemIndx <= -2
            }"
            [disabled]="docLoading || selectedItemIndx <= -2"
            [icon]="'../../../assets/img/prev-button.svg'"
            (click)="getPrevDoc()"
          >
          </d1861-button>

          <ng-container *ngIf="selectedItemIndx >= 0 && needActions">
            <ng-container *ngTemplateOutlet="actionButtons"></ng-container>
          </ng-container>

          <d1861-button
            [ngClass]="{
              'disabled-event':
                docLoading || selectedItemIndx >= items.length - 1
            }"
            [disabled]="docLoading || selectedItemIndx >= items.length - 1"
            [icon]="'../../../assets/img/next-button.svg'"
            (click)="getNextDoc()"
          >
          </d1861-button>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<ng-template #actionButtons let-disabled="disabled">
  <d1861-button
    [ngClass]="{
      'disabled-event':
        !needActions ||
        actionButtonsDisabled ||
        docLoading ||
        selectedDoc.status === 'REJECTED'
    }"
    [disabled]="
      !needActions ||
      actionButtonsDisabled ||
      docLoading ||
      selectedDoc.status === 'REJECTED'
    "
    [icon]="'../../../assets/img/doc-rejected.svg'"
    [text]="'Отклонить'"
    (click)="reject()"
  >
  </d1861-button>
  <d1861-button
    [ngClass]="{
      'disabled-event':
        !needActions ||
        actionButtonsDisabled ||
        docLoading ||
        selectedDoc.status === 'POSTPONED'
    }"
    [disabled]="
      !needActions ||
      actionButtonsDisabled ||
      docLoading ||
      selectedDoc.status === 'POSTPONED'
    "
    [icon]="'../../../assets/img/warning.svg'"
    [text]="'Отложить'"
    (click)="postpone()"
  >
  </d1861-button>
  <d1861-button
    [ngClass]="{
      'disabled-event':
        !needActions ||
        actionButtonsDisabled ||
        docLoading ||
        selectedDoc.status === 'APPROVED'
    }"
    [disabled]="
      !needActions ||
      actionButtonsDisabled ||
      docLoading ||
      selectedDoc.status === 'APPROVED'
    "
    [icon]="'../../../assets/img/ok.svg'"
    [text]="'Одобрить'"
    (click)="approve()"
  >
  </d1861-button>
</ng-template>

<d1861-confirm-comment-dialog
  [display]="isRejectDialogShown"
  [action]="commentAction"
  [buttonText]="commentAction === 'reject' ? 'Отклонить' : 'Отложить'"
  (commentEvent)="commentHandler($event)"
></d1861-confirm-comment-dialog>

<d1861-approve-dialog
  [display]="isApprovedDialogShown"
  (continueEvent)="continueEvent()"
  (nextPackageEvent)="nextPackageEvent()"
  (exitEvent)="exitAfterEndModeration()"
></d1861-approve-dialog>

<d1861-exit-confirm-dialog
  [display]="isExitDialogShown"
  (continueEvent)="continueEvent()"
  (exitEvent)="exitEvent()"
>
</d1861-exit-confirm-dialog>

<d1861-premoderation-result-dialog
  [display]="isPreModerationResultShown"
  (continueEvent)="continueEvent()"
>
</d1861-premoderation-result-dialog>
