import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { TransportationTariffsService } from '../../services/transportation-tariffs.service';
import { FreightWithoutFinalWeight, FreightWithoutFinalWeightResponse } from '../../models/transportTariffs.model';
import { ImgService } from '../../services/img.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'shipped-weight',
  templateUrl: './shipped-weight.component.html',
  styleUrls: ['./shipped-weight.component.scss']
})
export class ShippedWeightComponent implements OnInit, OnDestroy {
  public freightList: FreightWithoutFinalWeight[] = [];
  isLoading: boolean = false;
  isUpdating: boolean = false;
  updatedCardIds: number[] = [];

  private notifier = new Subject();

  constructor(
    public imgService: ImgService,
    private router: Router,
    private trService: TransportationTariffsService,
    private primeMessageService: MessageService
  ) { }

  ngOnInit(): void {
    this.loadLFreightList();
  }

  loadLFreightList() {
    this.isLoading = true;
    this.trService.getFreightsWithoutFinalWight().subscribe((curResp: FreightWithoutFinalWeightResponse) => {
      if (curResp?.success) {
        this.freightList = curResp?.data.map((value: FreightWithoutFinalWeight) => ({
          ...value,
          forwardingStart: moment(value.forwardingStart).format('DD.MM.YYYY'),
          forwardingEnd: moment(value.forwardingEnd).format('DD.MM.YYYY'),
          curWeightKg: value.goodsWeight * 1000
        }));
        this.isLoading = false;
      }
    });
  }

  ngOnDestroy() {
    this.notifier.next(false);
    this.notifier.complete();
  }

  updateWeight(freight: FreightWithoutFinalWeight) {
    this.updatedCardIds.push(freight.id);
    this.isUpdating = true;
    this.trService.updateWeight(freight.id, freight.curWeightKg).subscribe((res) => {
      if (res.success) {
        let index = this.freightList.indexOf(freight);
        if (index !== -1) {
          this.freightList.splice(index, 1);
        }
        if (this.freightList.length === 0) {
          this.loadLFreightList();
        }
        this.primeMessageService.add({
          severity: 'success',
          summary: 'Изменения успешно сохранены',
          detail: 'Новые данные успешно сохранены в системе'
        });
      } else {
        this.updatedCardIds = this.updatedCardIds.filter(id => id !== freight.id);
      }
      this.isUpdating = false;
    });
  }
}
