<div class="page-block">
  <div class="page-header">
    <div>
      <h1>Тарифы на перевозку</h1>
    </div>
  </div>
  <div class="column gap16">
    <div class="filter-block">
      <div class="filter-block_sort">
        <div class="sort-wrapper">
          <div class="filter-label">Выберите регион:</div>
          <div class="flex-1">
            <p-dropdown [options]="regions" [optionLabel]="'comment'" [(ngModel)]="selectedRegion" placeholder=""
              (onChange)="regionChange()" [editable]="false"></p-dropdown>
          </div>
        </div>
      </div>
    </div>

    <div class="content">
      <div class="address">
        {{ this.selectedRegion?.comment }}
      </div>
      <p-table *ngIf="curTariffsList.length" [value]="curTariffsList" [tableStyle]="{ 'min-width': '50rem' }">
        <ng-template pTemplate="header">
          <tr>
            <th>Мин. дистанция</th>
            <th>Макс. дистанция</th>
            <th>Коэффициент 1</th>
            <th>Коэффициент 2</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-tariffLine>
          <tr>
            <td>{{ tariffLine.minDistance }}</td>
            <td>{{ tariffLine.maxDistance }}</td>
            <td>
              <input pInputText type="number" class="bold-text" title="" (keypress)="restrictInput($event)"
                [(ngModel)]="tariffLine.koef1">
            </td>
            <td>
              <input pInputText type="number" class="bold-text" title="" (keypress)="restrictInput($event)"
                [(ngModel)]="tariffLine.koef2">
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>

<div class="sticky-footer">
  <span>
    Если вы изменяли данные, не забудьте сохранить данные
  </span>
  <d1861-button (clickFunc)="saveTariffs()" [text]="'Применить настройки'" [iconPosition]="'left'"
    [disabled]="isUpdating" [ngClass]="isUpdating ? 'looping' : ''"
    [icon]="isUpdating ? '../../assets/process.svg' : '../../assets/img/round-white-check.svg'"
    [buttonColor]="'green'"></d1861-button>
</div>