import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import localeRu from '@angular/common/locales/ru';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { MenuModule } from 'primeng/menu';
import { DropdownModule } from 'primeng/dropdown';
import { ButtonModule } from 'primeng/button';
import { CascadeSelectModule } from 'primeng/cascadeselect';
import { FileUploadModule } from 'primeng/fileupload';
import { InputTextModule } from 'primeng/inputtext';
import { PaginatorModule } from 'primeng/paginator';
import { TooltipModule } from 'primeng/tooltip';
import { DialogModule } from 'primeng/dialog';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CheckboxModule } from 'primeng/checkbox';
import { AccordionModule } from 'primeng/accordion';
import { ToastModule } from 'primeng/toast';
import { CardModule } from 'primeng/card';
import { SkeletonModule } from 'primeng/skeleton';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TableModule } from 'primeng/table';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ButtonComponent } from '@data1861/data1861-lib/dist/lib1861';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { ConfirmCommentComponent } from './components/dialogs/confirm-comment/confirm-comment.component';
import { NewItemsListComponent } from './components/new-items-list/new-items-list.component';
import { DetailedListComponent } from './components/detailed-list/detailed-list.component';
import { ApproveDialogComponent } from './components/dialogs/approve/approve-dialog.component';
import { ExitConfirmDialogComponent } from './components/dialogs/exit-confirm/exit-confirm-dialog.component';
import { ApprovedItemsListComponent } from './components/approved-items-list/approved-items-list.component';
import { PremoderationResultDialogComponent } from './components/dialogs/premoderation-result/premoderation-result-dialog.component';
import { HtmlPreviewComponent } from './components/detailed-list/html-preview/html-preview.component';
import { PackageListItemComponent } from './components/elements/package-list-item/package-list-item.component';
import { ConfigService } from './services/config.service';
import { KeycloakService } from './services/keycloak.service';
import { AuthInterceptor } from './d1861-utils/http.interceptor';
import { DocumentsService } from './services/documents.service';
import { TransportationTariffsComponent } from './components/transportation-tariffs/transportation-tariffs.component';
import { ShippedWeightComponent } from './components/shipped-weight/shipped-weight.component';
import { ForwardOrdersComponent } from './components/forward-orders/forward-orders.component';
import { ForwardSettingsComponent } from './components/forward-settings/forward-settings.component';
import { OfferComponent } from './components/forward-orders/offer/offer.component';
import { MessageService } from 'primeng/api';

registerLocaleData(localeRu, 'ru');

@NgModule({
  declarations: [
    AppComponent,
    ConfirmCommentComponent,
    ApproveDialogComponent,
    ExitConfirmDialogComponent,
    PremoderationResultDialogComponent,
    HeaderComponent,
    SidebarComponent,
    NewItemsListComponent,
    ApprovedItemsListComponent,
    TransportationTariffsComponent,
    ForwardSettingsComponent,
    ShippedWeightComponent,
    ForwardOrdersComponent,
    HtmlPreviewComponent,
    DetailedListComponent,
    PackageListItemComponent,
    OfferComponent
  ],
  imports: [
    CommonModule,
    PdfViewerModule,
    ToggleButtonModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MenuModule,
    PaginatorModule,
    DropdownModule,
    ButtonModule,
    CascadeSelectModule,
    FileUploadModule,
    ProgressSpinnerModule,
    InputTextModule,
    DialogModule,
    SkeletonModule,
    AutoCompleteModule,
    CheckboxModule,
    TooltipModule,
    AccordionModule,
    CardModule,
    TableModule,
    ButtonComponent,
    ToastModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    { provide: LOCALE_ID, useValue: 'ru' },
    ConfigService,
    KeycloakService,
    DocumentsService,
    MessageService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
