<div class="page-block">
  <div class="page-header">
    <div>
      <h1>Настройки экспедирования</h1>
    </div>
  </div>

  <div class="wrapper">
    <div class="input-wrapper">
      <div class="label">
        Вознаграждение экспедитора по умолчанию
      </div>
      <div class="p-inputgroup">
        <p-inputNumber class="bold-text green" [(ngModel)]="fee" mode="decimal" [maxFractionDigits]="2">
        </p-inputNumber>
        <span class="p-inputgroup-addon">₽ / т</span>
      </div>
    </div>

    <div class="input-wrapper">
      <div class="label">
        Невостребованность заявки по истечении
      </div>
      <div class="p-inputgroup">
        <p-inputNumber class="bold-text" [(ngModel)]="days" mode="decimal" [maxFractionDigits]="0"> </p-inputNumber>
        <span class="p-inputgroup-addon">дней</span>
      </div>
    </div>

  </div>
</div>

<div class="sticky-footer">
  <span>
    Если вы изменяли данные, не забудьте сохранить данные
  </span>
  <d1861-button (clickFunc)="updateSettings()" [text]="'Применить настройки'" [iconPosition]="'left'"
    [buttonColor]="'green'" [disabled]="isUpdating" [ngClass]="isUpdating ? 'looping' : ''"
    [icon]="isUpdating ? '../../assets/process.svg' : '../../assets/img/round-white-check.svg'"></d1861-button>
</div>