import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import * as moment from 'moment';
import { TransportationTariffsService } from '../../services/transportation-tariffs.service';
import { Freight, FreightResponse } from '../../models/transportTariffs.model';

@Component({
  selector: 'forward-orders',
  templateUrl: './forward-orders.component.html',
  styleUrls: ['./forward-orders.component.scss']
})
export class ForwardOrdersComponent implements OnInit, OnDestroy {
  totalCount: number = 0;
  isLoading: boolean = false;
  ordersList: Freight[] = [];

  private notifier = new Subject();
  private tableOffset: number = 0;
  private tableLimit: number = 20;

  constructor(
    private trService: TransportationTariffsService,
  ) { }

  ngOnInit(): void {
    this.loadOrders();
  }

  ngOnDestroy() {
    this.notifier.next(false);
    this.notifier.complete();
  }

  onPageChange(event: {
    page: number;
    first: number;
    rows: number;
    pageCount: number;
  }) {
    this.tableLimit = event.rows;
    this.tableOffset = event.first;
    this.loadOrders();
  }

  loadOrders() {
    this.isLoading = true;
    this.trService.getOutdatedRequestList(this.tableOffset, this.tableLimit).subscribe((res: FreightResponse) => {
      if (res.success) {
        this.ordersList = res.data.requests.map((freight: Freight) => ({
          ...freight,
          forwardingEndDate: moment(freight.forwardingEndDate).format('DD.MM.YYYY'),
          forwardingStartDate: moment(freight.forwardingStartDate).format('DD.MM.YYYY'),
        }));
        this.totalCount = res.data.total;
        this.isLoading = false;
      }
    });
  }
}
