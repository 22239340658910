import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'd1861-exit-confirm-dialog',
  templateUrl: './exit-confirm-dialog.component.html',
  styleUrls: ['./exit-confirm-dialog.component.scss']
})
export class ExitConfirmDialogComponent {
  @Input() display!: boolean;

  @Output() continueEvent = new EventEmitter<string>();

  @Output() exitEvent = new EventEmitter<string>();

  public continue() {
    this.continueEvent.emit('continue');
  }

  public exit() {
    this.exitEvent.emit('exit');
  }
}
