<p-dialog
  [(visible)]="loginIsVisible"
  [modal]="true"
  [appendTo]="'body'"
  [closeOnEscape]="false"
  [draggable]="false"
  [closable]="false"
>
  <div class="login-content">
    <div *ngIf="!forgotPasswordFormIsVisible" class="title">Вход в аккаунт</div>
    <div *ngIf="forgotPasswordFormIsVisible" class="title">Забыли пароль?</div>

    <div
      *ngIf="resetPasswordSuccessNoteIsVisible"
      class="reset-password-status"
    >
      <img src="assets/round-green-check.svg" alt="" />

      <span
        >В ближайшее время вы должны получить письмо с дальнейшими
        инструкциями.</span
      >
    </div>

    <div *ngIf="loginError" class="error-login-status">
      <img src="assets/error.svg" alt="" />

      <span
        >В процессе авторизации возникла ошибка. Проверьте правильность
        введенных данных.</span
      >
    </div>

    <form
      *ngIf="!forgotPasswordFormIsVisible"
      [formGroup]="loginForm"
      class="content-form"
    >
      <div class="input-block">
        <p class="text-area-label">E-mail *</p>
        <div class="input-container">
          <input id="email" name="email" formControlName="email" pInputText />
          <div
            [ngClass]="{
              'invalid-field-flag':
                loginForm.controls['email'].errors &&
                loginForm.controls['email'].dirty,
              'valid-field-flag':
                !loginForm.controls['email'].errors &&
                loginForm.controls['email'].value
            }"
            class="error-flag"
          ></div>
        </div>
        <p
          *ngIf="
            loginForm.controls['email'].errors &&
            loginForm.controls['email'].dirty
          "
          class="invalid-field-note"
        >
          Неправильно заполнено поле
        </p>

        <div
          style="content: ''; height: 16px"
          *ngIf="
            !loginForm.controls['email'].dirty ||
            !loginForm.controls['email'].errors
          "
        ></div>
      </div>

      <div class="input-block">
        <p class="text-area-label">Пароль *</p>
        <div class="input-container">
          <input
            id="password"
            name="password"
            type="password"
            formControlName="password"
            autocomplete="on"
            pInputText
            [type]="passwordIsVisible ? 'text' : 'password'"
          />
          <svg
            (click)="passwordIsVisible = !passwordIsVisible"
            class="password-icon"
          >
            <use
              *ngIf="passwordIsVisible"
              xlink:href="assets/password-icons.svg#show-password"
            ></use>
            <use
              *ngIf="!passwordIsVisible"
              xlink:href="assets/password-icons.svg#hide-password"
            ></use>
          </svg>
          <div
            [ngClass]="{
              'invalid-field-flag':
                loginForm.controls['password'].errors &&
                loginForm.controls['password'].dirty,
              'valid-field-flag':
                !loginForm.controls['password'].errors &&
                loginForm.controls['password'].value
            }"
            class="error-flag"
          ></div>
        </div>
        <p
          *ngIf="
            loginForm.controls['password'].errors &&
            loginForm.controls['password'].dirty
          "
          class="invalid-field-note"
        >
          Введенный пароль не подходит
        </p>
        <div
          style="content: ''; height: 16px"
          *ngIf="
            !loginForm.controls['password'].dirty ||
            !loginForm.controls['password'].errors
          "
        ></div>
      </div>
      <div class="under-form-block">
        <p (click)="forgotPassword()" href="">Забыли пароль?</p>
      </div>

      <div class="button-block">
        <d1861-widget-button
          (clickFunc)="login()"
          [disabled]="loginForm.invalid"
          [text]="'Войти'"
          [style]="
            'width:520px;height: 48px;display:flex;justify-content:center'
          "
        >
        </d1861-widget-button>
      </div>
    </form>

    <form
      *ngIf="forgotPasswordFormIsVisible"
      [formGroup]="forgotPasswordForm"
      class="content-form"
    >
      <div class="input-block">
        <p class="text-area-label">E-mail *</p>
        <div class="input-container">
          <input
            id="forgot-password-email"
            name="forgot-password-email"
            formControlName="email"
            pInputText
          />
          <div
            [ngClass]="{
              'invalid-field-flag':
                forgotPasswordForm.controls['email'].errors &&
                forgotPasswordForm.controls['email'].dirty,
              'valid-field-flag':
                !forgotPasswordForm.controls['email'].errors &&
                forgotPasswordForm.controls['email'].value
            }"
            class="error-flag"
          ></div>
        </div>
        <p
          *ngIf="
            forgotPasswordForm.controls['email'].errors &&
            forgotPasswordForm.controls['email'].dirty
          "
          class="invalid-field-note"
        >
          Неправильно заполнено поле
        </p>

        <p
          *ngIf="
            forgotPasswordForm.controls['email'].errors &&
            forgotPasswordForm.controls['email'].errors['204error']
          "
          class="invalid-field-note"
        >
          {{ forgotPasswordForm.controls['email'].errors['204error'] }}
        </p>

        <p
          *ngIf="
            forgotPasswordForm.controls['email'].errors &&
            forgotPasswordForm.controls['email'].errors['409error']
          "
          class="invalid-field-note"
        >
          {{ forgotPasswordForm.controls['email'].errors['409error'] }}
        </p>

        <div
          style="content: ''; height: 16px"
          *ngIf="
            !forgotPasswordForm.controls['email'].dirty ||
            !forgotPasswordForm.controls['email'].errors
          "
        ></div>
      </div>

      <d1861-widget-button
        (clickFunc)="resetPassword()"
        [disabled]="forgotPasswordForm.invalid"
        [style]="
          'width: 100%;height: 48px;display:flex;justify-content:center;margin-bottom:56px'
        "
        [text]="'Подтвердить'"
      >
      </d1861-widget-button>
    </form>
  </div>
</p-dialog>
