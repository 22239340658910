import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { KeycloakService } from '../services/keycloak.service';

@Injectable()
export class LoginGuard {
  constructor(
    protected router: Router,
    protected keycloakAngular: KeycloakService
  ) {}

  canActivate() {
    let returnVar = false;

    if (!this.keycloakAngular.isLoggedIn()) {
      returnVar = true;
    }
    if (this.keycloakAngular.isLoggedIn()) {
      this.router.navigate(['']);

      returnVar = false;
    }
    return returnVar;
  }
}
