<div class="grid wrapper">
  <div class="col-8 grid gap16 stretch">
    <div class="col-6 grid gap16">
      <div class="col-6 row gap24 border-right">
        <img [src]="'../../../../assets/img/goods/' + imgService.getIconName(order.freightName) + '.svg'">
        <div class="number">
          № <span class="bold-text">{{order.id}}</span>
        </div>
      </div>

      <div class="col-4 bold-text border-right no-left">{{order.freightName}}</div>

      <div class="col-2 bold-text border-right no-left"><strong>{{order.cropYear}}</strong></div>
    </div>

    <div class="col-6 grid gap16">
      <div class="col-3 number border-right no-left">
        <span class="bold-text">{{order.requestedWeightInTons}}</span> т
      </div>

      <div class="col-3 sampling border-right no-left">
        <div>Проба:</div>
        <img *ngIf="order.sampling" [src]="'../../../../assets/img/sampling.svg'">
        <img *ngIf="!order.sampling" [src]="'../../../../assets/img/no-sampling.svg'">
      </div>

      <div class="col-6 dates border-right no-left">
        с {{order.forwardingStartDate}} по {{order.forwardingEndDate}}
      </div>
    </div>
  </div>

  <div class="col-4 grid gap16 stretch">
    <div class="col-6 column gap8 border-right no-left">
      <div class="address-title">{{order.loading.title}}</div>
      <div class="address">{{order.loading.address}}</div>
    </div>

    <div class="col-6 row gap24 center min-width">
      <div class="p-inputgroup">
        <p-inputNumber class="bold-text green" name="price" [(ngModel)]="order.forwarderFee" [maxFractionDigits]="0"
          [min]="0" locale="ru-RU"></p-inputNumber>
        <span class="p-inputgroup-addon">₽ / т</span>
      </div>

      <d1861-button [disabled]="!order.forwarderFee" (clickFunc)="updateValue(order)" [smallButton]="true"
        [disabled]="isUpdating" [ngClass]="isUpdating ? 'looping' : ''"
        [icon]="isUpdating ? '../../assets/process.svg' : '../../assets/round-green-check.svg'"></d1861-button>
    </div>
  </div>
</div>