import { Component, Input, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { HttpResponse } from '@angular/common/http';
import { ButtonRoundComponent, WidgetButtonComponent } from '@components1861';
import { Subject, takeUntil } from 'rxjs';
import { LoginService } from 'src/app/services/login-service.service';
import { KeycloakService } from 'src/app/services/keycloak.service';
import { Router } from '@angular/router';

@Component({
  selector: 'd1861-login-form',
  standalone: true,
  imports: [
    CommonModule,
    DialogModule,
    FormsModule,
    ReactiveFormsModule,
    CheckboxModule,
    ButtonModule,
    InputTextModule,
    ButtonRoundComponent,
    WidgetButtonComponent
  ],
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnDestroy {
  @Input() loginIsVisible: boolean = true;

  private PasswordSymbolsPattern: RegExp = /^[0-9()а-яА-ЯёЁ0#№.,\-/a-zA-Z]*$/;

  public passwordIsVisible: boolean = false;

  public forgotPasswordFormIsVisible: boolean = false;

  public resetPasswordSuccessNoteIsVisible: boolean = false;

  private EmailPattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

  private destroy$ = new Subject();

  public loginForm: FormGroup = new FormGroup({
    email: new FormControl('', [
      Validators.required,
      Validators.pattern(this.EmailPattern)
    ]),
    password: new FormControl('', [
      Validators.required,
      Validators.pattern(this.PasswordSymbolsPattern),
      Validators.minLength(5)
    ])
  });

  public forgotPasswordForm: FormGroup = new FormGroup({
    email: new FormControl('', [
      Validators.required,
      Validators.pattern(this.EmailPattern)
    ])
  });

  public loginError: boolean = false;

  constructor(
    private loginService: LoginService,
    private readonly router: Router,
    private readonly keycloak: KeycloakService
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  public login() {
    const userName = this.loginForm.get('email')?.value;
    const password = this.loginForm.get('password')?.value;
    if (userName && password) {
      this.loginError = false;

      this.loginService
        .login(userName, password)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (data) => {
            if (
              this.keycloak
                .checkRoleForLogin(data.data.access_token)
                .includes('MODERATOR')
            ) {
              this.keycloak.login(
                data.data.access_token,
                data.data.refresh_token,
                data.data.expires_in
              );
            } else {
              this.loginError = true;
              this.loginForm.get('password')?.reset();
            }
          },
          () => {
            this.loginError = true;
            this.loginForm.get('password')?.reset();
          }
        );
    }
  }

  public forgotPassword() {
    this.loginError = false;
    this.forgotPasswordFormIsVisible = true;
    this.resetPasswordSuccessNoteIsVisible = false;
  }

  public resetPassword() {
    const emailAddressForReset = this.forgotPasswordForm.get('email')?.value;
    if (emailAddressForReset) {
      this.loginService
        .resetPassword(emailAddressForReset)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (data: HttpResponse<any>) => {
            if (data.status === 200) {
              this.resetPasswordSuccessNoteIsVisible = true;
              this.forgotPasswordFormIsVisible = false;
              this.forgotPasswordForm.reset();
            }
            if (data.status === 204) {
              this.forgotPasswordForm.get('email')?.reset();
              this.forgotPasswordForm.get('email')?.setErrors({
                '204error':
                  'Что-то пошло не так. Убедитесь что почтовый адрес указан верно'
              });
            }
          },
          (error) => {
            if (error.status === 409) {
              this.forgotPasswordForm.get('email')?.reset();
              this.forgotPasswordForm.get('email')?.setErrors({
                '409error': 'Запрос на восстановления пароля уже отправлен.'
              });
            }
          }
        );
    }
  }
}
