<div class="header">
  <div class="logo" routerLink="/new">
    <img src="assets/img/logo.svg" alt="Логотип Data1861.ru" />
  </div>

  <div class="menu-block">
    <button
      pButton
      routerLink="/shipped_weight"  routerLinkActive='active-tab'
      (click) = " this.menuService.curMenuState = 1;"
      [class.active_menu_item] = "this.menuService.curMenuState == 1"
     >
      <svg>
        <use xlink:href="./assets/img/header-menu-icons.svg#trade-platform"/>
      </svg>
      <span class="button-label">Торговая платформа</span>
    </button>
    <button
      routerLink="/new"
      routerLinkActive="active-tab"
      pButton
      (click) = " this.menuService.curMenuState = 2;"
      [class.active_menu_item] = "this.menuService.curMenuState == 2"
      >
      <svg>
        <use xlink:href="./assets/img/header-menu-icons.svg#accreditation"/>
      </svg>
      <span class="button-label">Аттестация</span>
    </button>

  </div>
  <div class="user-block">
    <div class="personal-block-wrapper">
      <div class="user-block" (click)="userMenu.toggle($event)">
        <img src="assets/img/personal-block/user-icon.svg" alt="" />
        <span *ngIf="userProfile">
          {{ this.userProfile.username }}
        </span>
        <button pButton *ngIf="userProfile">
          <img
            [ngClass]="{ rotate: arrowRotation }"
            src="assets/img/personal-block/dropdown-arrow.svg"
            alt=""
          />
        </button>
        <p-menu
          (event)="onEvent()"
          styleClass="dropdown-menu"
          [popup]="true"
          #userMenu
          [model]="items"
        ></p-menu>
      </div>
    </div>
  </div>

</div>
<div class=" =subMenu">
  <d1861-sidebar *ngIf="path != '/login'"></d1861-sidebar>

</div>
