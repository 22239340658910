import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { BaseHttpService, BaseResult } from './base-http-service';
import {
  DefaultForwarderFeeResponse, DefaultValuesResponse, FreightResponse, FreightWithoutFinalWeightResponse,
  RegionResponse,
  TariffResponse
} from '../models/transportTariffs.model';

@Injectable({
  providedIn: 'root'
})
export class TransportationTariffsService extends BaseHttpService {
  public getRegionsList(): Observable<RegionResponse> {
    const curUrl = `${this.baseRestURL}best/price/moderator/tariff/regions`;
    return this.http.get<RegionResponse>(curUrl);
  }

  public getTariffList(
    regionId: number,
    limit: number = 10,
    offset: number = 10
  ): Observable<TariffResponse> {
    const curUrl = `${this.baseRestURL}best/price/moderator/tariff/list`;
    const params = new HttpParams()
      .set('regionId', regionId)
      .set('limit', limit)
      .set('offset', offset);

    return this.http.get<TariffResponse>(curUrl, { params });
  }

  public updateTariff(id: number, koef1: number, koef2: number): Observable<BaseResult> {
    const curUrl = `${this.baseRestURL}best/price/moderator/tariff/update`;
    return this.http.post<BaseResult>(curUrl, { id, koef1, koef2 }, {});
  }

  /*public getDefaultForwarderFee(): Observable<DefaultForwarderFeeResponse> {
    const curUrl = `${this.baseRestURL}forwarding/moderator/forwarder_fee/default`;
    const params = new HttpParams();
    //  .set('goodsType', goodsType);

    return this.http.get<DefaultForwarderFeeResponse>(curUrl, { params });
  }
*/
  public getDefaultConfigValues(): Observable<DefaultValuesResponse> {
    const curUrl = `${this.baseRestURL}forwarding/moderator/config`;
    return this.http.get<DefaultValuesResponse>(curUrl, {  });
  }

  public updateDefaultConfigValues(notificationIntervalDays: string, defaultForwarderFee: string): Observable<BaseResult> {
    const curUrl = `${this.baseRestURL}forwarding/moderator/config`;
    return this.http.post<BaseResult>(curUrl, { notificationIntervalDays,  defaultForwarderFee}, {});
  }

  public updateRequestForwarderFee(requestId: number, newForwarderFee: number): Observable<BaseResult> {
    const curUrl = `${this.baseRestURL}forwarding/moderator/forwarder_fee`;
    return this.http.post<BaseResult>(curUrl, { requestId, newForwarderFee }, {});
  }

  public getOutdatedRequestList(
    offset: number = 0,
    limit: number = 20
  ): Observable<FreightResponse> {
    const curUrl = `${this.baseRestURL}forwarding/moderator/outdated_request/list`;
    const params = new HttpParams()
      .set('limit', limit)
      .set('offset', offset);

    return this.http.get<FreightResponse>(curUrl, { params });
  }

  public getFreightsWithoutFinalWight(): Observable<FreightWithoutFinalWeightResponse> {
    const curUrl = `${this.baseRestURL}order/moder/bargain/finalWeight/list`;
    return this.http.get<FreightWithoutFinalWeightResponse>(curUrl);
  }

  public updateWeight(bargainId: number, weightKg: number): Observable<BaseResult> {
    const curUrl = `${this.baseRestURL}order/moder/bargain/${bargainId}/finalWeight`;
    return this.http.post<BaseResult>(curUrl, { weightKg }, {});
  }
}
