<p-dialog 
  [(visible)]="display"
  [resizable]="false"
  [draggable]="false"
  [closable]="false"
  [modal]="true">
  <div class="p-dialog-container">
    <h3>Вернуться к списку заявок</h3>
    <div class="warning-section">
      Вы не закончили модерацию пакета документов!
    </div>

    При возвращению к списку документов <span class="highlighted">ваш рабочий процесс сохранится</span>, а пакет документов будет иметь статус «отложено». 
    Пакет документов станет доступным для обработки другим модераторам.

    <div class="buttons-section">
      <button 
        pButton 
        class="p-button-raised p-button-secondary exit" 
        type="button" 
        label="Выйти" 
        (click)="exit()">
      </button>
      <button 
        pButton
        class="p-button-raised p-button-secondary continue"
        type="button" 
        label="Продолжить обработку" 
        (click)="continue()">
      </button>

    </div>
  </div>
</p-dialog>