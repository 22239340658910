import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  first,
  interval,
  map,
  Observable,
  of,
  Subject,
  takeUntil,
  tap
} from 'rxjs';
import { RestApiService } from '../../services/api.service';
import { DocumentsService } from '../../services/documents.service';
import {
  DocumentsAPIResponseModel,
  DocumentInterace,
  DocStatuses
} from '../../models/newDocument.model';

@Component({
  selector: 'd1861-new-items-list',
  templateUrl: './new-items-list.component.html',
  styleUrls: ['./new-items-list.component.scss']
})
export class NewItemsListComponent implements OnInit, OnDestroy {
  private notifier = new Subject();

  public docStatuses = DocStatuses;

  public itemsNew$!: Observable<DocumentsAPIResponseModel>;

  public itemsCount$!: Observable<any>;

  public refreshButtonShown$: Observable<Boolean> = of(false);

  public refreshInterval$!: Observable<number>;

  public paginationSettings = {
    currentPage: 0,
    rows: 10,
    rowsPerPageOptions: [10, 20, 30]
  };

  constructor(
    private router: Router,
    private apiService: RestApiService,
    private docService: DocumentsService
  ) {}

  public navigate(paramItem: DocumentInterace) {
    let item = paramItem;

    if (item.type === 'ACCREDITATE_DOCUMENT') {
      const packageInfoObj = {
        packageInfo: {
          id: item.documentInfo.packageId,
          name: ''
        }
      };
      item = { ...paramItem, ...packageInfoObj };
      this.docService.setDoc(item);
      this.router.navigate(['/detailed-list'], {
        queryParams: {
          docId: item.id
        }
      });
    }
    if (item.type === 'ACCREDITATE_PACKAGE') {
      this.docService.setDoc(item);
      this.router.navigate(['/detailed-list']);
    }
  }

  ngOnInit(): void {
    this.refreshList();
    interval(30 * 1000)
      .pipe(
        takeUntil(this.notifier),
        tap(() => {
          this.checkNewPackages();
        })
      )
      .subscribe();
  }

  private checkNewPackages() {
    this.refreshButtonShown$ = this.getCountData(0, 1).pipe(
      takeUntil(this.notifier),
      map((item: any) => !!item?.data.count)
    );
  }

  public refreshList() {
    this.refreshButtonShown$ = of(false);
    this.itemsCount$ = this.getCountData(
      this.paginationSettings.currentPage,
      this.paginationSettings.rows
    );
    this.itemsNew$ = this.getData(
      this.paginationSettings.currentPage,
      this.paginationSettings.rows
    );
  }

  public paginate(event: any) {
    this.itemsNew$ = this.getData(event.page * event.rows, event.rows);
  }

  private getCountData(currentPage: number, rows: number): Observable<void> {
    return this.apiService
      .requestsCountNew(currentPage, rows, ['ACCREDITATE_PACKAGE'])
      .pipe(first());
  }

  private getData(
    currentPage: number,
    rows: number
  ): Observable<DocumentsAPIResponseModel> {
    return this.apiService
      .requestsNew(currentPage, rows, ['ACCREDITATE_PACKAGE'])
      .pipe(first());
  }

  ngOnDestroy() {
    this.notifier.next(false);
    this.notifier.complete();
  }
}
