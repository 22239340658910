<p-dialog
  [(visible)]="display"
  [resizable]="false"
  [draggable]="false"
  [closable]="false"
  [modal]="true"
>
  <div class="p-dialog-container">
    <h3>Все документы пакета обработаны</h3>
    <div class="org-info">
      <h4>Организация:</h4>
      <span>{{ (packageUser$ | async)?.user?.name }}</span>
      <span
        >{{ (packageUser$ | async)?.user?.inn
        }}{{ (packageUser$ | async)?.user?.address }}</span
      >
    </div>
    <div class="org-info">
      <h4>Пакет документов:</h4>
      <span>{{ packageName }}</span>
    </div>
    <div
      class="moderation-results"
      [class]="(packageStatus$ | async)?.COMPLETE_STATUS"
    >
      <span class="subheader">Результат модерации:</span>
      <ng-container [ngSwitch]="(packageStatus$ | async)?.COMPLETE_STATUS">
        <ng-container *ngSwitchCase="'REJECTED'">
          <h4>Пакет документов <span class="hightlight">был отклонен</span></h4>
          Один или несколько документов из пакета был отклонён модератором, в
          результате чего, не может получить аккредитацию. Запрос на обработку
          был перемещён в список отработанных заявок. Владелец получит
          уведомление об ошибке в предоставленном документе. После исправления
          ошибок пользователем, модератор получит запрос на повторную обработку
          в списке задач.
        </ng-container>

        <ng-container *ngSwitchCase="'APPROVED'">
          <h4>
            Пакет документов
            <span class="hightlight">получил аккредитацию</span>
          </h4>
          Все документы пакета были просмотрены и получили одобрение модератора.
          Пакет документом получил аккредитацию. Запрос на обработку был
          перемещён в список отработанных заявок. Данный пакет будет доступен
          для просмотра другими пользователями по запросу.
        </ng-container>

        <ng-container *ngSwitchCase="'POSTPONED'">
          <h4>
            Пакет документов
            <span class="hightlight">отложен для дальнейшей работы</span>
          </h4>
          Обработка одного или несколько документов из пакета была отложена
          модератором. Запрос на обработку останется в списке задач, и будет
          доступен для обработки другими модераторами, либо вами. После принятия
          положительного или отрицательного решения, в отношении отложенных
          документов, запрос на обработку модератором переместится в список
          обработанных заявок и будет принято решение по статусу аккредитации
          пакета документов.
        </ng-container>
      </ng-container>
    </div>

    <div class="buttons-section">
      <d1861-button
        [icon]="'../../../assets/img/back-to-list.svg'"
        [text]="'К списку документов'"
        (click)="exit()"
      ></d1861-button>

      <d1861-button
        [icon]="'../../../assets/img/package-edit.svg'"
        [text]="'Продолжить обработку'"
        (click)="continue()"
      ></d1861-button>

      <d1861-button
        [buttonColor]="'green'"
        [icon]="'../../../assets/img/next-package.svg'"
        [text]="'Следующий пакет'"
        (click)="next()"
      ></d1861-button>
    </div>
  </div>
</p-dialog>
