<div class="page-block">
  <div class="page-header">
    <div>
      <h1>Заявки на экспедирование</h1>
    </div>
  </div>

  <div class="column gap16">
    <div class="actions">
      <d1861-button (clickFunc)="{}" [text]="'Невостребованные заявки'" [buttonColor]="'green'"
        [icon]="'../../assets/img/outdated-orders-icon.svg'"></d1861-button>
    </div>

    <div class="container" *ngIf="ordersList.length || isLoading">
      <div class="grid headers">
        <div class="col-8 grid gap16">
          <div class="col-6 grid gap16">
            <div class="col-6 no-left">№ Заявки</div>
            <div class="col-4 no-left">Культура</div>
            <div class="col-2 no-left">Год урожая</div>
          </div>

          <div class="col-6 grid gap16">
            <div class="col-3 no-left">Количество</div>
            <div class="col-3 no-left">Проба</div>
            <div class="col-6 no-left">Дата</div>
          </div>
        </div>

        <div class="col-4 grid gap16">
          <div class="col-6 no-left">Базис отгрузки</div>
          <div class="col-6 min-width no-left">Вознаграждение экспедитора</div>
        </div>
      </div>

      <div class="offers-list" *ngIf="!isLoading">
        <offer *ngFor="let order of ordersList" (reload)="loadOrders()" [order]="order"></offer>
      </div>
      <div class="offers-list" *ngIf="isLoading">
        <p-skeleton class="offer-skeleton"></p-skeleton>
        <p-skeleton class="offer-skeleton"></p-skeleton>
      </div>
    </div>

    <div class="no-results" *ngIf="!isLoading && !ordersList.length">
      Ничего не найдено
    </div>

    <p-paginator *ngIf="totalCount" [rows]="10" [totalRecords]="totalCount" [rowsPerPageOptions]="[5, 10, 15, 20]"
      (onPageChange)="onPageChange($event)"></p-paginator>
  </div>
</div>