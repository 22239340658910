<div class="content" *ngIf="data?.data as companyCardData">
  <h2>Информация о компании</h2>
  <div class="personal-item">
    <h3>ИНН</h3>
    <div class="personal-item__value">
      {{ companyCardData?.inn }}
    </div>
  </div>

  <div *ngIf="companyCardData?.ogrn" class="personal-item">
    <h3 *ngIf="companyCardData.userType === 'LEGAL_ENTITY'">ОГРН</h3>
    <h3 *ngIf="companyCardData.userType !== 'LEGAL_ENTITY'">ОГРНИП</h3>
    <div class="personal-item__value">
      {{ companyCardData?.ogrn }}
    </div>
  </div>

  <div *ngIf="companyCardData?.okpo" class="personal-item">
    <h3>ОКПО</h3>
    <div class="personal-item__value">
      {{companyCardData?.okpo}}
    </div>
  </div>

  <div *ngIf="companyCardData?.okato" class="personal-item">
    <h3>ОКАТО</h3>
    <div class="personal-item__value">
      {{companyCardData?.okato}}
    </div>
  </div>

  <div *ngIf="companyCardData?.okved" class="personal-item">
    <h3>ОКВЭД</h3>
    <div class="personal-item__value">
      {{companyCardData?.okved + ' ' + companyCardData?.okvedName}}
    </div>
  </div>

  <div *ngIf="companyCardData?.kpp" class="personal-item">
    <h3>КПП</h3>
    <div class="personal-item__value">
      {{companyCardData?.kpp}}
    </div>
  </div>

  <div *ngIf="companyCardData?.registrationDate" class="personal-item">
    <h3>Дата регистрации компании</h3>
    <div class="personal-item__value">
      {{companyCardData?.registrationDate}}
    </div>
  </div>

  <div class="personal-item">
    <h3>Адрес регистрации</h3>
    <div class="personal-item__value">
      {{companyCardData?.registrationAddress}}
    </div>
  </div>
      
  <div class="personal-item">
    <h3>Адрес для направления корреспонденции</h3>
    <div class="personal-item__value">
      {{companyCardData?.postAddress}}
    </div>
  </div>

  <div *ngIf="companyCardData?.belongsToGroup" class="personal-item">
    <h3>Принадлежность к группе компаний</h3>
    <div class="personal-item__value">
      {{companyCardData?.belongsToGroup}}
    </div>
  </div>

  <div *ngIf="companyCardData?.belongsEvidence" class="personal-item">
    <h3>Основание принадлежности к ГК</h3>
    <div class="personal-item__value">
      {{companyCardData?.belongsEvidence}}
    </div>
  </div>
  
  <div class="personal-item">
    <h3>Сайт</h3>
    <div class="personal-item__value">
      {{companyCardData?.site}}
    </div>
  </div>

  <div class="personal-item">
    <h3>Генеральный директор</h3>
    <div class="personal-item__value">
      {{companyCardData?.managementName}}
    </div>
  </div>

  <div class="personal-item" *ngIf="companyCardData.taxSystems.length">
    <h3>Система налогообложения</h3>
    <ng-container *ngIf="taxSystemsHasChecked; else noTaxSystem">
      <ng-container *ngFor="let taxSystem of companyCardData.taxSystems">
        <div class="personal-item__value" *ngIf="taxSystem.checked">
          {{taxSystem.name}}
        </div>
      </ng-container>
    </ng-container>
    <ng-template #noTaxSystem>
      <div class="personal-item__value">
        Не указано
      </div>
    </ng-template>
  </div>
  
  <div class="personal-item">
    <div *ngFor="let contact of companyCardData.contactPhones">
      <h3>{{ contact.contactName }}</h3>
      <div class="personal-item__value">
        {{contact?.phoneNumber}}
      </div>
    </div>
  </div>
</div>