import { Component, Input, OnChanges } from '@angular/core';
import { CompanyCardResultDto } from 'src/app/models/newDocument.model';

@Component({
  selector: 'd1861-html-preview',
  templateUrl: './html-preview.component.html',
  styleUrls: ['./html-preview.component.scss']
})
export class HtmlPreviewComponent implements OnChanges {
  @Input() data!: CompanyCardResultDto | null;

  public taxSystemsHasChecked: boolean | undefined = false;

  ngOnChanges() {
    this.taxSystemsHasChecked = this.data?.data.taxSystems.some((el) => el.checked === true);
  }
}
