import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'd1861-confirm-comment-dialog',
  templateUrl: './confirm-comment.component.html',
  styleUrls: ['./confirm-comment.component.scss']
})
export class ConfirmCommentComponent {
  @Input() display: boolean = false;

  @Input() buttonText: string = '';

  @Input() action: string = '';

  @Output() commentEvent = new EventEmitter<string>();

  public submit(form: NgForm) {
    const { value } = form.controls['comment'];
    form.reset();
    this.commentEvent.emit(value);
  }

  public closeForm() {
    this.commentEvent.emit('');
  }
}
