import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';

@Injectable()
export class BaseHttpService {
    protected baseRestURL = '';

    constructor(protected http: HttpClient, protected config: ConfigService) {
        this.baseRestURL = config.baseRestURL;
    }
}

export interface BaseResult {
    success: boolean;

    error?: string;
}

