<div class="item"      
  *ngFor="let item of items"
  (click)="click(item)"
  class="{{item.status}}"
  [ngClass]="{
    'disabled': item.status === docStatuses['IN_PROGRESS'] && (currendId$ | async)?.data?.id !== item.moderatorId,
    'no-border': handledView
  }"
  >
    <div class="_logo" [ngClass]="item.status"></div>
    <div class="_data">
      <span class="org-name">{{item.user.name}}</span>
      <span class="org-info">{{item.user.inn}} {{item.user.address}}</span>
      <ng-container [ngSwitch]="item.type">
        <ng-container *ngIf="!handledView;">
          <span class="info-package" *ngSwitchCase="'ACCREDITATE_PACKAGE'">
            <span>Загружены документы:</span> {{item.packageInfo.name}}
          </span>
          <span class="info-document" *ngSwitchCase="'ACCREDITATE_DOCUMENT'">
            Загружен дополнительный документ: {{item.documentInfo.name}}
          </span>
        </ng-container>
        <ng-container *ngIf="handledView;">
          <span class="info-package" *ngSwitchCase="'ACCREDITATE_PACKAGE'">
            <span>Загружены все документы пакета аккредитации:</span> {{item.packageInfo.name}}
          </span>
          <span class="info-document" *ngSwitchCase="'ACCREDITATE_DOCUMENT'">
            <span>Загружен дополнительный документ:</span> {{item.documentInfo.name}}
          </span>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="!handledView; else handledViewStatus">
        <span class="moderate-status">
          <span>Документ редактируется модератором:</span> {{item.moderatorName}}
        </span>
        <span class="moderate-status-postponed">
            <span>Отложено модератором:</span> {{item.moderatorName}}
        </span>
      </ng-container>

      <ng-template #handledViewStatus>
        <span class="moderate-status">
          <span>Обработано модератором:</span> {{item.moderatorName}}
        </span>
        <span class="moderate-status-rejected">
          <span class="postponed">Отклонено модератором:</span> {{item.moderatorName}}
        </span>
      </ng-template>

    </div>
    <div class="_date">
    <span class="doc-time">{{item.createdDate | date: 'HH:mm'}}</span>
    <span class="doc-date">{{item.createdDate | date: 'dd/MM/yyyy'}}</span>
    </div>
</div>