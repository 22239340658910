import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  finalize,
  first,
  map,
  Observable,
  of,
  shareReplay,
  Subject,
  takeUntil,
  tap
} from 'rxjs';
import {
  CompleteStatuses,
  DetailedPackage,
  PremoderationStatus,
  DocReadness,
  DocTypes,
  DocChilds,
  DocumentInterace,
  SingleDocumentAPIResponseModel,
  CompanyCardResultDto
} from 'src/app/models/newDocument.model';
import { RestApiService } from 'src/app/services/api.service';
import { DocumentsService } from 'src/app/services/documents.service';

@Component({
  selector: 'd1861-detailed-list',
  templateUrl: './detailed-list.component.html',
  styleUrls: ['./detailed-list.component.scss']
})
export class DetailedListComponent implements OnInit, OnDestroy {
  public items$!: Observable<DetailedPackage>;

  public fullSize: boolean = false;

  public needActions: boolean = false;

  public itemHtml$!: Observable<CompanyCardResultDto>;

  public selectedItemIndx: number = -2;

  public docLoading = false;

  public loadingError = false;

  public packageUser!: DocumentInterace['user'];

  private currentRequestId!: number;

  private currentPackageId!: number;

  public isRejectDialogShown: boolean = false;

  public isApprovedDialogShown: boolean = false;

  public isExitDialogShown: boolean = false;

  public isPreModerationResultShown: boolean = false;

  public preModerationFailed: boolean = false;

  public commentAction = '';

  public userId!: number;

  public isRefusedByUser: boolean = true;

  public selectedDoc!: DetailedPackage['data']['content'][0];

  public selectedDocType!: DocTypes;

  public actionButtonsDisabled = true;

  public DocTypes = DocTypes;

  public expandedComment = false;

  // private userProfile: KeycloakProfile;

  private notifier = new Subject();

  public exitSubject = new Subject<boolean>();

  public srcLink: any;

  public pdfSettings = {
    page: 1,
    zoom_to: 1,
    totalPages: 1
  };

  public imgSettings = {
    page: 0,
    zoom_to: 1,
    totalPages: 0
  };

  @ViewChild('docPreview') docPreview!: ElementRef;

  @ViewChild('imgPreview') imgPreview!: ElementRef;

  constructor(
    private activatedroute: ActivatedRoute,
    private apiService: RestApiService,
    private router: Router,
    private docService: DocumentsService
  ) {
    this.docService.setActiveDocId(null);
    this.currentRequestId = this.docService.getDoc().id;
    this.currentPackageId = this.docService.getDoc().packageInfo.id;
    this.activatedroute.queryParams
      .pipe(takeUntil(this.notifier))
      .subscribe((queryParams) => {
        this.needActions = !queryParams['handled'] || false;
        const paramDocId = queryParams['docId'];
        if (paramDocId) {
          this.items$ = this.items$.pipe(
            takeUntil(this.notifier),
            tap((items: DetailedPackage) => {
              this.userId = items.data.userId;
              const activeDocId = paramDocId;
              if (activeDocId) {
                const itemIndx = items.data.content.findIndex(
                  (item) => item.documentId === activeDocId
                );
                this.getDocument(items.data.content[itemIndx], itemIndx);
              }
            })
          );
        }
      });
  }

  public openExitDialog() {
    this.isExitDialogShown = true;
  }

  private loadPackage(paramId: number): Observable<DetailedPackage> {
    return this.apiService.getPackage(paramId).pipe(
      // eslint-disable-next-line no-return-assign
      tap((items: DetailedPackage) => (this.userId = items.data.userId))
      // возможно надо будет применить - порядок документов не соответствует их id
      /* map((arr) => {
          arr.data.content.sort((a, b) => {
            if (a.documentId > b.documentId) {
              return 1;
            }
            return -1;
          });
          return arr;
        }) */
    );
  }

  public getCompanyInfo(): void {
    this.showHtmlDocument();
    this.itemHtml$ = this.apiService
      .getCompanyInfo(this.userId)
      .pipe(shareReplay());
  }

  public getOpenInfo() {
    this.docLoading = true;
    this.isRefusedByUser = false;
    this.selectedDocType = DocTypes.empty;
    this.selectedDoc = {} as DetailedPackage['data']['content'][0];
    this.apiService
      .getOpenInfo(this.currentPackageId)
      .pipe(
        takeUntil(this.notifier),
        finalize(() => {
          this.docLoading = false;
        })
      )
      .subscribe({
        next: (template) => {
          this.loadingError = false;
          this.showDoc(template);
        },
        error: () => {
          this.loadingError = true;
        }
      });
  }

  private showHtmlDocument() {
    this.selectedDoc = {} as DetailedPackage['data']['content'][0];
    this.loadingError = false;
    this.selectedDocType = DocTypes.html;
    this.actionButtonsDisabled = true;
  }

  public getDocument(
    doc: DetailedPackage['data']['content'][0],
    index: number = 0
  ): void {
    this.selectedDocType = DocTypes.empty;
    if (doc) {
      if (doc.premoderationStatus === PremoderationStatus.PRE_REJECTED) {
        this.preModerationFailed = true;
        this.isPreModerationResultShown = true;
      }
      this.selectedDoc = doc;
      this.selectedDocType = DocTypes.file;
      this.isRefusedByUser = doc.isRefusedByUser;
      if (!doc.isRefusedByUser && doc.documentId) {
        this.showDocument(doc.documentId, doc.isComplex, doc.childs);
      }
    }
    this.loadingError = true;
    this.actionButtonsDisabled = false;
    this.selectedItemIndx = index;
  }

  private showDocument(
    docId: number,
    isArch: boolean = false,
    childs: DocChilds[] = [{} as DocChilds]
  ): void {
    this.docLoading = true;
    this.expandedComment = false;
    this.loadingError = false;
    this.clearContent();
    this.resetImgSettings();
    this.resetPdfSettings();
    if (isArch) {
      if (childs?.length) {
        this.imgSettings.totalPages = childs.length;
        this.composeImgSet(docId, childs);
      } else {
        this.loadingError = true;
        this.docLoading = false;
      }
    } else {
      this.imgSettings.totalPages = 1;
      this.composeSingleDoc(docId);
    }
  }

  private composeImgSet(docId: number, childs: DocChilds[]) {
    childs.forEach((document, index) => {
      this.composeSingleDoc(docId, document.id, index);
    });
  }

  // eslint-disable-next-line class-methods-use-this
  private clearContent() {
    const insertedContent = document.querySelectorAll('.insertedContent');
    // eslint-disable-next-line no-restricted-syntax
    insertedContent.forEach((elem) => elem.remove());
  }

  private showDoc(template: any, num?: number) {
    const name = template.headers.get('content-disposition');
    const data = template.body;
    const fileNameArr = `${decodeURI(name!).split("''")[1]}`.split('.');
    const ext = name !== null ? fileNameArr[fileNameArr.length - 1] : 'pdf';
    if (data) {
      const file = new Blob([data], {
        type: ext === 'pdf' ? 'application/pdf' : 'image/png'
      });
      if (ext === 'pdf' || ext === undefined) {
        this.selectedDocType = DocTypes.pdf;
        const fileReader = new FileReader();
        fileReader.onload = () => {
          this.srcLink = new Uint8Array(fileReader.result as ArrayBuffer);
        };
        fileReader.readAsArrayBuffer(file);
      } else {
        this.selectedDocType = DocTypes.image;
        const fileURL = URL.createObjectURL(file);
        const img = `<img id="img${
          num || 0
        }" class="insertedContent" src=${fileURL} width='100%'></img>`;
        this.imgPreview.nativeElement.insertAdjacentHTML('afterBegin', img);
      }
    }
  }

  private composeSingleDoc(docId: number, childId?: number, num?: number) {
    let apiName = this.apiService.downloadDoc(docId);
    if (childId) {
      apiName = this.apiService.downloadChildDoc(docId, childId);
    }
    apiName
      .pipe(
        takeUntil(this.notifier),
        finalize(() => {
          this.docLoading = false;
        })
      )
      .subscribe({
        next: (template) => {
          this.loadingError = false;
          this.showDoc(template, num);
        },
        error: () => {
          this.loadingError = true;
        }
      });
  }

  zoom_in() {
    if (this.pdfSettings.zoom_to <= 2) {
      this.pdfSettings.zoom_to += 0.25;
    }
    if (this.selectedDocType === DocTypes.image) {
      this.imgSettings.zoom_to += 0.25;
      this.zoomImg();
    }
  }

  zoom_out() {
    if (this.pdfSettings.zoom_to >= 0) {
      this.pdfSettings.zoom_to -= 0.25;
    }
    if (this.selectedDocType === DocTypes.image) {
      this.imgSettings.zoom_to -= 0.25;
      this.zoomImg();
    }
  }

  private zoomImg() {
    const insertedContent = Array.from(
      document.getElementsByClassName('insertedContent')
    );
    if (insertedContent && insertedContent.length) {
      insertedContent.forEach((item) => {
        item.setAttribute('width', `${this.imgSettings.zoom_to * 100}%`);
      });
    }
  }

  pdfNextPage() {
    this.pdfSettings.page = Math.min(
      this.pdfSettings.page + 1,
      this.pdfSettings.totalPages
    );
  }

  pdfPrevPage() {
    this.pdfSettings.page = Math.max(this.pdfSettings.page - 1, 1);
  }

  imgNextPage() {
    // this.imgSettings.page = Math.min(this.imgSettings.page + 1, this.imgSettings.totalPages);
    const page = Math.min(
      this.imgSettings.page + 1,
      this.imgSettings.totalPages
    );
    const img = document.getElementById(`img${page}`);
    this.scrollParentToChild(this.imgPreview.nativeElement, img);
  }

  imgPrevPage() {
    // this.imgSettings.page = Math.max(this.imgSettings.page - 1, 0);
    const page = Math.max(this.imgSettings.page - 1, 0);
    const img = document.getElementById(`img${page}`);
    this.scrollParentToChild(this.imgPreview.nativeElement, img);
  }

  public onScroll(event: any) {
    const images = event.srcElement.getElementsByClassName('insertedContent');
    const scrolledFromTop = event.srcElement.scrollTop;
    Array.from(images).forEach((image: any, index: number) => {
      if (scrolledFromTop >= image.offsetTop) {
        this.imgSettings.page = index;
      }
    });
  }

  // eslint-disable-next-line class-methods-use-this
  private scrollParentToChild(parent: any, child: any) {
    const parentRect = parent.getBoundingClientRect();
    const childRect = child.getBoundingClientRect();
    const scrollTop = childRect.top - parentRect.top + 10;
    // eslint-disable-next-line no-param-reassign
    parent.scrollTop += scrollTop;
  }

  afterLoadComplete(pdf: any): void {
    this.pdfSettings.totalPages = pdf.numPages;
  }

  private resetPdfSettings() {
    this.pdfSettings = {
      page: 1,
      zoom_to: 1,
      totalPages: 1
    };
  }

  private resetImgSettings() {
    this.imgSettings = {
      page: 0,
      zoom_to: 1,
      totalPages: 0
    };
  }

  public toggleFullSize() {
    this.fullSize = !this.fullSize;
  }

  public reject() {
    this.commentAction = 'reject';
    this.isRejectDialogShown = true;
  }

  public postpone() {
    this.commentAction = 'postpone';
    this.isRejectDialogShown = true;
  }

  public approve() {
    this.apiService
      .approve(this.selectedDoc.documentId, this.currentRequestId)
      .pipe(takeUntil(this.notifier))
      .subscribe(() => {
        this.setLocalStatus('APPROVED');
        this.setLocalComment();
        this.checkAllDocsStatus();
        this.getNextDoc();
      });
  }

  public commentHandler(comment: string) {
    if (comment) {
      if (this.commentAction === 'reject') {
        this.setLocalStatus('REJECTED');
        this.setLocalComment(comment);
        this.apiService
          .reject(this.selectedDoc.documentId, this.currentRequestId, comment)
          .pipe(takeUntil(this.notifier))
          .subscribe();

        this.checkAllDocsStatus();
      } else if (this.commentAction === 'postpone') {
        this.setLocalStatus('POSTPONED');
        this.setLocalComment(comment);
        this.apiService
          .postpone(this.selectedDoc.documentId, this.currentRequestId, comment)
          .pipe(takeUntil(this.notifier))
          .subscribe();
        this.checkAllDocsStatus();
      }
      this.getNextDoc();
    }

    this.isRejectDialogShown = false;
    this.commentAction = '';
  }

  public continueEvent() {
    this.isApprovedDialogShown = false;
    this.isExitDialogShown = false;
    this.isPreModerationResultShown = false;
    this.exitSubject.next(false);
  }

  public nextPackageEvent() {
    this.isApprovedDialogShown = false;
    this.endModeration().subscribe(() => {
      this.apiService
        .getNextPackage(this.currentRequestId)
        .pipe(
          first(),
          takeUntil(this.notifier),
          tap((response: SingleDocumentAPIResponseModel) => {
            if (response.data) {
              this.selectedItemIndx = -2;
              this.selectedDoc = {} as DetailedPackage['data']['content'][0];
              this.docService.setDoc(response.data);
              this.startHandler();
            } else {
              this.needActions = false;
              this.router.navigate(['/new']);
            }
          })
        )
        .subscribe();
    });
  }

  public showExitWindow() {
    if (this.needActions) {
      this.router.navigate(['/new']);
    } else {
      this.router.navigate(['/handled']);
    }
  }

  public exitEvent() {
    this.endModeration()
      .pipe(takeUntil(this.notifier))
      .subscribe(() => {
        this.isApprovedDialogShown = false;
        this.exitSubject.next(true);
      });
  }

  public exitAfterEndModeration() {
    this.endModeration().subscribe(() => {
      this.needActions = false;
      this.router.navigate(['/new']);
    });
  }

  private endModeration(): Observable<void> {
    if (this.needActions) {
      return this.apiService
        .finishModeraton(this.currentRequestId)
        .pipe(takeUntil(this.notifier));
    }
    return of();
  }

  private setLocalStatus(status: string) {
    const selectedDocDocumentId = this.selectedDoc.documentId;
    this.items$ = this.items$.pipe(
      map((items: DetailedPackage) => {
        // eslint-disable-next-line consistent-return
        // eslint-disable-next-line array-callback-return
        items.data.content.find((item: any) => {
          if (item.documentId && item.documentId === selectedDocDocumentId) {
            // eslint-disable-next-line no-param-reassign
            item.status = status;
          }
        });

        this.docService.setProfileObs(items);
        return items;
      })
    );
  }

  private setLocalComment(comment?: string) {
    const selectedDocDocumentId = this.selectedDoc.documentId;
    this.items$ = this.items$.pipe(
      map((items: DetailedPackage) => {
        // eslint-disable-next-line consistent-return
        // eslint-disable-next-line array-callback-return
        items.data.content.find((item: any) => {
          if (item.documentId && item.documentId === selectedDocDocumentId) {
            if (comment) {
              // eslint-disable-next-line no-param-reassign
              item.moderatorComment = comment;
            }
            // eslint-disable-next-line no-param-reassign
            item.moderatorName = 'Вы';
          }
        });
        this.docService.setProfileObs(items);
        return items;
      })
    );
  }

  private checkAllDocsStatus(): void {
    const res: DocReadness = {
      TOTAL: 0,
      REJECTED: 0,
      POSTPONED: 0,
      IN_PROGRESS: 0,
      APPROVED: 0,
      NEW: 0,
      COMPLETE_STATUS: CompleteStatuses.EMPTY
    };
    this.items$
      .pipe(
        tap((items: DetailedPackage) => {
          items.data.content.forEach((item) => {
            res.TOTAL += 1;
            switch (item.status) {
              case 'REJECTED':
                res.REJECTED += 1;
                break;
              case 'POSTPONED':
                res.POSTPONED += 1;
                break;
              case 'NEW':
                res.NEW += 1;
                break;
              case 'IN_PROGRESS':
                res.IN_PROGRESS += 1;
                break;
              case 'APPROVED':
                res.APPROVED += 1;
                break;
              default:
                break;
            }
          });
        }),
        first()
      )
      .subscribe();
    if (res.TOTAL === res.APPROVED + res.POSTPONED + res.REJECTED) {
      let finalDesition = CompleteStatuses.APPROVED;
      if (res.REJECTED) {
        finalDesition = CompleteStatuses.REJECTED;
      }
      if (res.POSTPONED) {
        finalDesition = CompleteStatuses.POSTPONED;
      }
      res.COMPLETE_STATUS = finalDesition;
      this.docService.setDocReadness(res);
      this.isApprovedDialogShown = true;
    }
  }

  public getNextDoc() {
    this.actionButtonsDisabled = true;
    switch ((this.selectedItemIndx += 1)) {
      case -2:
        this.getCompanyInfo();
        break;
      case -1:
        this.getOpenInfo();
        break;
      default:
        this.actionButtonsDisabled = false;
        this.items$.pipe(first()).subscribe((data: DetailedPackage) => {
          this.getDocument(
            data.data.content[this.selectedItemIndx],
            this.selectedItemIndx
          );
          if (this.selectedItemIndx >= data.data.content.length) {
            this.selectedItemIndx -= 1;
          }
        });
        break;
    }
  }

  public getPrevDoc() {
    this.actionButtonsDisabled = true;
    switch ((this.selectedItemIndx -= 1)) {
      case -2:
        this.getCompanyInfo();
        break;
      case -1:
        this.getOpenInfo();
        break;
      default:
        this.actionButtonsDisabled = false;
        this.items$.pipe(first()).subscribe((data: DetailedPackage) => {
          this.getDocument(
            data.data.content[this.selectedItemIndx],
            this.selectedItemIndx
          );
        });
        break;
    }
  }

  private startPage() {
    if (this.currentRequestId) {
      this.items$ = this.loadPackage(this.currentPackageId).pipe(
        shareReplay(),
        takeUntil(this.notifier)
        // tap(() => {
        //   this.getCompanyInfo();
        // })
      );
      this.loadPackage(this.currentPackageId).subscribe(() => {
        this.getCompanyInfo();
      });
    }
  }

  private startHandler() {
    this.packageUser = this.docService.getDoc().user;
    this.currentRequestId = this.docService.getDoc().id;
    this.currentPackageId = this.docService.getDoc().packageInfo.id;
    if (this.needActions) {
      this.apiService
        .startModeraton(this.currentRequestId)
        .pipe(takeUntil(this.notifier))
        .subscribe({
          next: () => {
            this.startPage();
          },
          error: () => {
            this.router.navigate(['/new']);
          }
        });
    } else {
      this.startPage();
    }
  }

  ngOnInit() {
    this.startHandler();
  }

  ngOnDestroy() {
    this.notifier.next(false);
    this.notifier.complete();
  }
}
